import ERC720ABI from "../config/abi/erc20.json";
import ERC721ABI from "../config/abi/erc721.json";
import MARKETPLACE from "../config/abi/marketplace.json";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
//https://www.npmjs.com/package/@ethersproject/contracts
const getContract = (abi, address, provider) => {
  return new ethers.Contract(address, abi, provider);
};

export const getErc720Contract = (address, provider) => {
  return getContract(ERC720ABI, address, provider);
};
export const getErc721Contract = (address, provider) => {
  let contract = getContract(ERC721ABI, address, provider);
  console.log("ERC721 contract -----------> ", contract);
  return contract;
};

export const getMarketplaceContract = (address, provider) => {
  return getContract(MARKETPLACE, address, provider);
};

export const getEstimateGasPrice = (fun) => {
  return fun.estimateGas;
};
