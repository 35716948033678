import React, { createContext, useState } from "react";
import {
  createClient,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useProvider,
} from "wagmi";

export const WalletConnectContext = createContext();
function WalletConnectProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const { connect, connectAsync, connectors, isLoading, pendingConnector } =
    useConnect();
  const provider = useProvider({ chain });
  const { disconnect } = useDisconnect();

  function handleModal(value) {
    setIsOpen(value);
  }

  return (
    <WalletConnectContext.Provider
      value={{
        isOpen,
        handleModal: handleModal,
        connect: connect,
        chain: chain,
        disconnect: disconnect,
        connectAsync: connectAsync,
        setIsAuthenticated: setIsAuthenticated,
        isAuthenticated: isAuthenticated,
        isConnected: isConnected,
        connectors: connectors,
        address: address,
        pendingConnector: pendingConnector,
        isLoading: isLoading,
        provider: provider,
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  );
}

export default WalletConnectProvider;
