import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { TransactionContext } from "../../Provider/TransactionProvide";
import { truncateAddress } from "../../utils";

export default function TransactionModal() {
  const [isTransactionOpen, setTransactionModal] = useState(false);
  const { TransactionDetails, addTransactionDetails } =
    useContext(TransactionContext);
  console.log(TransactionDetails);
  return (
    <>
      <Transition appear show={isTransactionOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setTransactionModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-4 py-10 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-primaryWhite px-6 py-10 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-3xl mb-5 font-bold leading-6 text-black"
                  >
                    <span> Transactions</span>
                    <div className="absolute top-0 w-[95%] right-0 flex justify-end p-5 ">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setTransactionModal(false);
                        }}
                      >
                        <RxCross2 size={20} className="" />
                      </span>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base text-[#ffffff99]">
                      your recent transactions you have performed on platforms.
                    </p>
                  </div>

                  <div className="mt-4">
                    <div className="flex flex-col gap-4">
                      <div className="min-h-[100px]  ">
                        <div className="text-center items-center flex  text-black h-full flex-col justify-center">
                          <div className="grid grid-cols-3 gap-2 w-full bg-primaryPink rounded-md py-2  mb-5">
                            <div>Txn</div>
                            <div>Event</div>
                            <div>Status</div>
                          </div>
                          {/* <button
                            onClick={() =>
                              addTransactionDetails({
                                txn: "0x1234567890abcdef",
                                name: "Approve",
                                status: "failed",
                              })
                            }
                          >
                            ADD
                          </button> */}

                          <div className="w-full flex  flex-col gap-3 overflow-y-scroll  min-h-[200px]">
                            {TransactionDetails.map((item, key) => (
                              <>
                                <div
                                  key={key}
                                  className="h-[52px] w-full rounded-lg justify-between py-2.5 px-5 text-md font-semibold text-black flex items-center gap-5   bg-primaryWhite shadow"
                                >
                                  <div className="flex-1 w-1/3">
                                    {truncateAddress(item.txn)}
                                  </div>
                                  <div className="flex-1 w-1/3 text-center">
                                    {item.name}
                                  </div>
                                  {"confirm" === item.status && (
                                    <div className="flex-1 w-1/3 inset-0 bg-[#5cc08526]  px-5   text-[14px] rounded-full text-[#5cc085]">
                                      Confirm
                                    </div>
                                  )}
                                  {"failed" === item.status && (
                                    <div className=" w-1/3  inset-0 bg-[#ff343f29]  px-5   text-[14px] rounded-full text-[#ff343f]">
                                      Failed{" "}
                                    </div>
                                  )}

                                  {"pending" === item.status && (
                                    <div className="  inset-0 w-1/3  bg-[#c7b95b3d]  px-5   text-[14px] rounded-full text-[#c7b95b]">
                                      Pending{" "}
                                    </div>
                                  )}
                                </div>
                              </>
                            ))}
                            {TransactionDetails.length === 0 &&
                              "No Recent transaction"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
