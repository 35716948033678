import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ParticleBackground from "../components/particaleJs/ParticleBackground";
import MainLayout from "../Layout/MainLayout";
import CreatePage from "../pages/CreatePage";
import CreatorPage from "../pages/CreatorPage";
import CreatorProfile from "../pages/CreatorProfile";
import EditProfile from "../pages/EditProfile";
import ExplorePage from "../pages/ExplorePage";
import HomePage from "../pages/HomePage";
import ItemPage from "../pages/ItemPage";
import Profile from "../pages/Profile";
import About from "../pages/Static/About";
import Privacy from "../pages/Static/Privacy";
import Terms from "../pages/Static/Terms";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import HowItWorksPage from "../pages/Static/HowItWorks";

function Router() {
  const { handleModal, isConnected } = useContext(WalletConnectContext);
  const location = useLocation();
  const UserConnect = ({ children }) => {
    useEffect(() => {
      if (!isConnected) {
        handleModal(true);
      }
    }, [location]);
    return children;
  };
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index path="/" element={<HomePage />} />
        <Route path="explore" element={<ExplorePage />} />
        <Route
          path="/asset/:network/:address/:tokenId"
          element={<ItemPage />}
        />

        <Route
          path="create"
          element={
            <UserConnect>
              <CreatePage />
            </UserConnect>
          }
        />
        <Route path="creators" element={<CreatorPage />} />
        <Route path="about" element={<About />} />
        <Route
          path="edit-profile"
          element={
            <UserConnect>
              <EditProfile />
            </UserConnect>
          }
        />
        <Route
          path="profile"
          element={
            <UserConnect>
              <Profile />
            </UserConnect>
          }
        />
        <Route path="creator-profile/:address" element={<CreatorProfile />} />
        <Route
          path="part"
          element={
            <div className="h-[200px] w-full">
              <ParticleBackground />
            </div>
          }
        />

        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="how-it-works" element={<HowItWorksPage />} />
      </Route>
    </Routes>
  );
}

export default Router;
