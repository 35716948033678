import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className='bg-blue-50'>
        <section className="container">
          <div className="border border-dashed border-gray-500 mt-2 mb-2"></div>
          <div className="flex flex-wrap  lg:flex-row	flex-col content-around">
            <div className="flex lg:w-1/4 justify-center lg:justify-start lg:mb-0 mb-4">
              <div className="mt-4">
                <Link to="/">
                  <img className="h-auto " src="/assets/images/logo.png" alt="" width="150" />
                </Link>
              </div>
            </div>
            <div className="flex lg:w-2/4 block --hidden">
              <div>              
                <ul className="flex flex-wrap justify-center my-6">
                  <li className="py-1 px-4">
                    <Link to="/" className="text-gray-600 hover:text-gray-700 font-medium" href="#">Home</Link>
                  </li>
                  <li className="py-1 px-4">
                    <Link to="/how-it-works" className="text-gray-600 hover:text-gray-700 font-medium" href="#">How it works</Link>
                  </li>
                  <li className="py-1 px-4">
                    <Link to="privacy" className="text-gray-600 hover:text-gray-700 font-medium" href="#">Privacy Policy</Link>
                  </li>
                  <li className="py-1 px-4">
                    <Link to="terms" className="text-gray-600 hover:text-gray-700 font-medium" href="#">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex lg:w-1/4 lg:mb-0 mb-4 justify-center lg:justify-end">
              <div className="flex justify-center space-x-2 pb-2">
                <img className="h-auto " src="/assets/images/1.svg" alt="" width="auto" />
                <img className="h-auto " src="/assets/images/2.svg" alt="" width="auto" />
                <img className="h-auto " src="/assets/images/3.svg" alt="" width="auto" />
                <img className="h-auto " src="/assets/images/4.svg" alt="" width="auto" />
              </div>
            </div>
          </div>

          {/* <div className="relative z-10 container px-4 mx-auto">
            <form className="max-w-md mx-auto">
              <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                <input
                  type="search"
                  id="default-search"
                  className="w-full block p-3 ps-10 text-sm text-gray-900 rounded-lg py-3 bg-white"
                  placeholder="Mail"
                  required
                />
                <button  type="submit" className="text-white absolute end-2 bottom-1 focus:outline-none font-lg rounded-lg text-sm px-2  py-2 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF]"> Subscribe</button>
              </div>
            </form>
          </div> */}

          <div className="relative z-10 container px-4 mx-auto py-6">
            <div>
              <p className="text-center text-sm text-gray-600">© 2022 Swadesi. All Rights Reserved.</p>
            </div>
          </div>

        </section>
      </div>    
    </>
  );
}

export default Footer;
