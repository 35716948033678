import { AiFillPicture } from "react-icons/ai";
import { IoIosMusicalNotes, IoMdSearch } from "react-icons/io";
import { MdSportsBaseball } from "react-icons/md";
import { GiComputing } from "react-icons/gi";
import { IoIdCardSharp } from "react-icons/io5";
export const IPFS_URL = "https://gateway.ipfscdn.io/ipfs/";
//export const GRAPHQL_API = "http://192.168.0.101:5000";
export const GRAPHQL_API = "https://api.swadesi.io/graphql";
export const DEFAULT_CHAIN_ID = 97;
export const DEFAULT_WRAP_TOKEN = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
export const DEFAULT_NATIVE_TOKEN_ADDRESS =
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const CATEGORY = [
  {
    name: "Art",
    icon: <AiFillPicture size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon.png"
  },
  {
    name: "Music",
    icon: <IoIosMusicalNotes size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon (1).png"
  },
  {
    name: "Sports",
    icon: <MdSportsBaseball size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon (2).png"
  },
  {
    name: "Utility",
    icon: <GiComputing size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon (3).png"
  },
  {
    name: "Domain Names",
    icon: <IoMdSearch size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon (4).png"
  },
  {
    name: "Trading Cards",
    icon: <IoIdCardSharp size={40} className="text-primaryPink" />,
    img: "/assets/images/Icon (5).png"
  },
];

export const STEP = 0.00001;
