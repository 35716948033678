import React, { useContext } from "react";
import { MdVerified } from "react-icons/md";

import { useParams } from "react-router-dom";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { useQuery } from "@apollo/client";
import { UserInfo } from "../graphql/query";
import { truncateAddress } from "../utils";
import ProfileNfts from "../components/Section/ProfileNfts";
function CreatorProfile() {
  const { address } = useParams();
  console.log(address);

  const { data: userInfo } = useQuery(UserInfo, {
    skip: !address,
    variables: {
      walletAddress: address,
    },
  });

  return (
    <div>
      {/* Banner Info */}
      <div className="py-[80px]">
        {/* //banner Image  */}
        <div className="px-10">
          <div className="group profile-banner relative overflow-hidden text-transparent lg:rounded-xl shadow dark:shadow-gray-700">
            <div className="relative shrink-0">
              <img
                src={userInfo?.user?.bg_image}
                className="h-80 w-full object-cover rounded-md"
                id="profile-banner"
                alt
              />
              <label className="absolute inset-0 " htmlFor="pro-banner" />
            </div>
          </div>
          {/* //User  INfor */}
          <div className="md:flex justify-center">
            <div className="md:w-full">
              <div className="relative -mt-[60px] text-center">
                <div className="group profile-pic w-[112px] mx-auto ">
                  <div>
                    <div className="relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                      <img
                        className="w-[110px] h-full  object-cover"
                        src={userInfo?.user?.avatar_url}
                      ></img>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex flex-col">
                  <div>
                    <h5 className="text-3xl gap-2 flex justify-center font-semibold text-black">
                      <span> {userInfo?.user?.username} </span>
                      {userInfo?.user?.isVerified && (
                        <span className="flex items-center text-primaryPink cursor-pointer">
                          <Tooltip
                            title="Verified"
                            position="bottom"
                            trigger="mouseenter"
                          >
                            <MdVerified size={20} />
                          </Tooltip>
                        </span>
                      )}
                    </h5>
                  </div>
                  <div>
                    <p className="text-slate-400 text-[16px] mt-1">
                      Created by{" "}
                      <span className="text-primaryPink font-semibold cursor-pointer">
                        <Tooltip
                          title={address}
                          position="bottom"
                          trigger="mouseenter"
                        >
                          {truncateAddress(address)}
                        </Tooltip>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="relative w-fit border border-[#ffffff1a] rounded-xl  px-5  py-3 shadow flex gap-8">
              <div className="text-center">
                <h3 className="mb-0 font-bold text-black">
                  {userInfo?.user?.nfts?.length}
                </h3>
                <h6 className="mb-0 text-sm font-semibold text-gray-400">
                  Total NFT
                </h6>
              </div>{" "}
              <div className="text-center">
                <h3 className="mb-0 font-bold text-black">
                  {userInfo?.user?.follower_list?.length}
                </h3>
                <h6 className="mb-0 text-sm font-semibold text-gray-400">
                  Followers
                </h6>
              </div>{" "}
              <div className="text-center">
                <h3 className="mb-0 font-bold text-black">
                  {" "}
                  {userInfo?.user?.following_list?.length}
                </h3>
                <h6 className="mb-0 text-sm font-semibold text-gray-400">
                  Following
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-5 md:px-[90px] py-[30px]">
        <ProfileNfts nfts={userInfo} />
      </div>
    </div>
  );
}

export default CreatorProfile;
