import React from "react";
import exploreBanner from "../../assets/img/exploreBanner.jpg";
import { useQuery } from "@apollo/client";
import { GetStaticPage } from "../../graphql/query";

function About() {

  const { data, loading, error } = useQuery(GetStaticPage, {
    variables: {
      type: 'about_us',
    },
  });

  return (
    <>
      <div className='bg-blue-50'>
        <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0]  px-4">
          <div className="container">
            <div className="row lg:flex block container pt-4">
              <div className="w-full">
                <h1 className="text-white text-center text-4xl  font-bold">{data?.getStaticPage?.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">
        <div className="container pt-16">

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className="w-full text-center">

              {/* <h1 className="text-2xl font-bold">Creative Vision &amp; Mission</h1> */}
              <h1 className="text-2xl font-bold">{data?.getStaticPage?.body?.heading}</h1>

              <h6 className="--font-semibold text-black ">
                  {/* We develop &amp; create digital art */}
                  {data?.getStaticPage?.body?.sub_heading}
              </h6>
              {/* <p className="py-4">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p> */}
              <div className="py-4" dangerouslySetInnerHTML={{ __html: data?.getStaticPage?.body?.html }} />

              {/* <div className="mt-6">
                <button
                  type="submit"
                  className="mt-5 font-bold bg-primaryPink text-white px-10 py-3 rounded-lg"
                >
                  Contact us
                </button>
              </div> */}

            </div>
          </div>

          <div className="h-32"></div>
          {/* <img
                  src="https://gateway.ipfscdn.io/ipfs/QmTyDRruwMvDh4vm5dd7P3yRzDyf5e29X6CHtWexyhpbw2/7.gif"
                  className="rounded-lg shadow-md dark:shadow-gray-800"
                  alt
                /> */}
        </div>
      </div>

    </>
  );
}

export default About;
