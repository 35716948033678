import React from "react";
import Lottie from "react-lottie";
import NFTLoading from "./nftLoading.json";
function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NFTLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="h-screen flex flex-col justify-center">
      <Lottie options={defaultOptions} height={400} width={400} />
      <h1 className="text-center font-bold text-black">Loading...</h1>
    </div>
  );
}

export default Loading;
