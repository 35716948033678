import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/loading.css";
import App from "./App";
import LoadingProvider from "./Provider/LoadingProvider";
import { BrowserRouter } from "react-router-dom";
import WalletProvider from "./Provider/WalletProvider";
import WalletConnectProvider from "./Provider/WalletConnectProvider";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import PutMarketplace from "./Provider/PutMarketplace";
import TransactionProvide from "./Provider/TransactionProvide";
const root = ReactDOM.createRoot(document.getElementById("root"));

const client = new ApolloClient({
  //uri: "http://localhost:5002/graphql",
  uri: "https://api.swadesi.io/graphql",
  cache: new InMemoryCache(),
});
root.render(
  <React.StrictMode>
    <WalletProvider>
      <PutMarketplace>
        <WalletConnectProvider>
          <TransactionProvide>
            <ApolloProvider client={client}>
              <LoadingProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </LoadingProvider>
            </ApolloProvider>
          </TransactionProvide>
        </WalletConnectProvider>
      </PutMarketplace>
    </WalletProvider>
  </React.StrictMode>
);
