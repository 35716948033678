import { useContext, useEffect } from "react";
import { LoadingContext } from "./Provider/LoadingProvider";
import Router from "./Router/Router";
import "./App.css";
import Loading from "./components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { WalletConnectContext } from "./Provider/WalletConnectProvider";
function App() {
  const { loading } = useContext(LoadingContext);

  const location = useLocation();
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  const ScrollTop = ({ children }) => {
    useEffect(() => window.scrollTo(0, 0), [location]);

    return children;
  };
  return (
    <div className="App">
      <ScrollTop>
        <Router />
      </ScrollTop>
    </div>
  );
}

export default App;
