import React, { useState } from "react";
import NFTCard from "../components/Cards/NFTCard";
import { BsWallet2 } from "react-icons/bs";
import { IoPricetags } from "react-icons/io5";
import { AiFillPicture, AiOutlineCloudUpload } from "react-icons/ai";
import { Link } from "react-router-dom";
import Particle from "../components/Particle";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { GetAllNfts, GetTopCreator, TopNFT, Users } from "../graphql/query";
import { ChainsInfo } from "../config/config-chains";
import CreatorCard from "../components/Cards/CreatorCard";
import { CATEGORY } from "../config/constant/url";

import { MoveLeft, MoveRight } from 'lucide-react';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;
function HomePage() {
  const { data: topNFT } = useQuery(TopNFT);
  const { data: getTopCreator } = useQuery(GetTopCreator);
  const { data: getAllNfts } = useQuery(GetAllNfts);
  const { data: users } = useQuery(Users);
  //console.log(getAllNfts?.nfts?.length);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  return (
    <>
      <div className='--bg-blue-50'>
        <div className="absolute z-[-1] top-0 left-0 w-full lg:h-full h-[80%] bg-cover bg-bottom lg:bg-[url('/assets/images/Bg.png')] bg-[url('/assets/images/mobilebg.svg')]  bg-no-repeat "></div>

        {/* <div className="absolute top-0 left-0 w-full z-[-1]">
          <img src="/assets/images/Bg.png"></img>
        </div> */}

        <div className="relative px-4">
          <div className="container">
            <div className="row lg:flex block container pt-4 lg:items-center">
              <div className="lg:w-2/4 w-full lg:p-4 p-0">

                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={300}
                  duration={900}
                  triggerOnce
                >
                  <h1 className="lg:text-5xl text-2xl text-white font-bold">Create, sell or collect  digital items.</h1>
                </Reveal>
                
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={600}
                  duration={900}
                  triggerOnce
                >
                  <p className="font-normal text-wrap text-lg text-white mt-4">Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable</p>
                </Reveal>

                <div className="h-[32px] xl:h-[62px]"></div>

                {/* <form className="mx-auto mt-4">
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div className="relative">
                    
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                    
                    <input type="search" id="default-search" className="w-full block  p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search course, event or author" required />
                    
                    <button type="submit" className="text-white absolute end-3.5 bottom-2 focus:outline-none font-lg rounded-full text-sm px-2  py-2 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF]"  >
                      <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        color="#fff"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </form> */}

                <div className="flex my-4 lg:mt-4  lg:gap-4 gap-1">
                  <div className="flex-initial w-1/3">
                    <div className="style-3 rounded-[10px] bg-white text-left h-full py-[20px] px-6">
                      <div className="mx-auto flex items-center justify-center">
                        <img className="lg:h-12 h-8" src="/assets/images/nft1.webp" alt="" width="auto" />
                      </div>
                      <div className="text-center">
                        <h5 className="lg:text-4xl text-lg font-bold bg-clip-text text-transparent bg-gradient-to-t from-sky-500 to-blue-500">
                            <a href="#">{getAllNfts?.nfts?.length || 0} +</a>
                        </h5>
                        <p className="font-rubik text-gray-700 text-[12px] lg:text-lg">Minted NFT</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-initial w-1/3">
                    <div className="style-3 rounded-[10px] bg-white text-left h-full py-[20px] px-6">
                      <div className="mx-auto flex items-center justify-center">
                        <img className="lg:h-12 h-8" src="/assets/images/24-support.webp" alt="" width="auto" />
                      </div>
                      <div className="text-center">
                        <h5 className="lg:text-4xl text-lg font-bold bg-clip-text text-transparent bg-gradient-to-t from-sky-500 to-blue-500">
                            <a href="#">{users?.users?.length || 0} +</a>
                        </h5>
                        <p className="font-rubik text-gray-700 text-[12px] lg:text-lg">Current User</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-initial w-1/3">
                    <div className="style-3 rounded-[10px] bg-white text-left h-full py-[20px] px-6">
                      <div className="mx-auto flex items-center justify-center">
                        <img className="lg:h-12 h-8" src="/assets/images/_NFT_Art_1_.webp" alt="" width="auto" />
                      </div>
                      <div className="text-center">
                        <h5 className="lg:text-4xl text-lg font-bold bg-clip-text text-transparent bg-gradient-to-t from-sky-500 to-blue-500">
                          <a href="#">5 +</a>
                        </h5>
                        <p className="font-rubik text-gray-700 text-[12px] lg:text-lg">Category</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-2/4 w-full">
                <img className="h-auto lg:block hidden" src="/assets/images/Illustration.png" alt="" width="auto" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NFTCollection title="New Items" data={topNFT?.getNewNfts} />

      {/* category page design start heere */}

      <div className='bg-blue-50'>
        <div className="mx-auto max-w-7xl px-6 lg:px-6 pb-4 ">
          <div className='container'>
            <div className="row flex py-4 p-4">
              <div className='w-full text-center mt-8'>
                <h2 className='text-md font-light'>Category</h2>
                <p className='text-3xl font-bold py-2'>Select your category</p>
                <p className='lg:text-9xl leading-[0] text-[#e2efff] mt-[-30px] lg:block hidden'>Category</p>
              </div>
            </div>
            <div className="grid xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 xl:gap-[10px] gap-2 !gap-y-[50px] lg:mt-[-30px] mt-6">

              {CATEGORY.map((item, key) => (
                <div key={key}>
                  <div className="bg-white col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 p-1 rounded-lg shadow-xl">
                    <div className="text-center py-4">
                      <img className="h-24 mx-auto mt-[-60px]" src={item.img} alt="" width="auto" />
                      {/* {item.icon} */}
                      <p className="text-slate-800 font-bold py-2 pb-2">{item.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* category page design end */}

      {/* Create and Sell Now page design start heere */}
      <div className='bg-blue-50 lg:pb-20 pb-5'>
        <div className="mx-auto max-w-7xl px-6 lg:px-6 pb-4 lg:pb-24 xl:pb-4">
            <div className='container'>
                <div className="row lg:flex block lg:py-4 py-0 lg:p-4 p-0">
                    <div className='lg:w-2/3 w-full mt-8'>
                        <div className="flex items-center justify-between flex-wrap gap-4">
                            <div className="flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8" fill="none"><circle cx="7" cy="7" r="3" fill="#9377FB"></circle></svg>
                                <span className="text-black text-xl font-light uppercase">how we work</span>
                            </div>
                        </div>
                        <h2 className='lg:text-4xl text-2xl text-black font-medium mt-2'>Create and <span className='text-[#9377FB]'>Sell Now</span></h2>

                        <div className="grid xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[15px] gap-4 lg:absolute w-full	 @responsive lg:w-3/4 xl:w-7/12	 mt-8">
                            <div>
                                <div className='bg-white p-6 rounded-lg lg:mt-4 mt-0 shadow-2xl'>
                                    <h2 className='py-2 text-[#636EF9] font-bold text-xl'>01</h2>
                                    <h3 className='py-4 text-black text-xl font-medium'>Set up your wallet</h3>
                                    <p className='text-gray-600 text-md '>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>

                                </div>
                            </div>
                            <div>
                                <div className='bg-white p-6 rounded-lg lg:mt-4 mt-0 shadow-2xl border-b-4 border-blue-600'>
                                    <h2 className='py-2 text-[#636EF9] font-bold text-xl'>02</h2>
                                    <h3 className='py-4 text-black text-xl font-medium'>Add your NFTs</h3>
                                    <p className='text-gray-600 text-md pb-6'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>

                                </div>
                            </div>
                            <div>
                                <div className='bg-white p-6 rounded-lg lg:mt-4 mt-0 shadow-2xl'>
                                    <h2 className='py-2 text-[#636EF9] font-bold text-xl'>03</h2>
                                    <h3 className='py-4 text-black text-xl font-medium'>Sell your NFTs</h3>
                                    <p className='text-gray-600 text-md'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className='lg:w-1/3 mt-8 w-full'>
                        <img className="h-auto lg:block hidden rounded-lg" src="/assets/images/digital-painting.svg" alt="" width="auto" />
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* Create and Sell Now page design end here */}

      <ArtistsCollection
        title="Top Sellers"
        data={getTopCreator?.getTopCreator}
      />

      <NFTCollection title="Hot Collection" data={topNFT?.getNewNfts} />

    </>
  );
}

const NFTCollection = ({ title, data }) => {

  if(data != undefined && data?.length > 0) {
    return (
      <>
        <div className='--bg-blue-50'>
          <div className="mx-auto max-w-7xl px-6 lg:px-6 pb-4 ">
            <div className='container' >
              <div className=" row flex py-4 p-4">
                <div className='w-2/3 mt-8'>
                  <p className='text-xl font-bold'>{title}</p>
                </div>
                <div className='w-1/3 mt-8 flex justify-end'>
                  {/* <div className="flex flex-end space-x-2">
                    <button className="bg-blue-500 bg-white text-black py-2 px-4 rounded"><MoveLeft strokeWidth={1.75} /></button>
                    <button className="bg-green-500 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white font-bold py-2 px-4 rounded"> <MoveRight strokeWidth={1.75} /></button>
                  </div> */}
                </div>
              </div>

              <div className="grid xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 xl:gap-[10px] gap-2">
              {data?.map((item, key) => (
                  <Link
                    key={key}
                    to={`/asset/${ChainsInfo[item.chainId].CURRENCY_SYMBOL}/${
                      item.contractAddress
                    }/${item.tokenId}`}
                  >
                    <NFTCard
                      name={item.name}
                      price={item.price}
                      chain={item.chainId}
                      image={item.imageUrl}
                      category={item.category}
                      owner={{
                        avatar_url:
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIL0qVUQJz_FAJmid8OrOh7rKzwB-LWXDbDkrHQpujVkp1I0vvXGZYEPT7aYj-Ji2QZyQ&usqp=CAU",
                        isVerified: false,
                        username: "hfsdhjfh",
                      }}
                    />
                  </Link>
                ))}
              </div>

            </div>
          </div>
        </div>

      </>
    );
  }

  return null;
};
const ArtistsCollection = ({ title, data }) => {
  
  if(data != undefined && data?.length > 0) {
    return (
      <>
      <div className='bg-blue-50'>
        <div className="mx-auto max-w-7xl px-6 lg:px-6 pb-4">
          <div className='container pb-12'>
            <div className="row flex py-4 p-4">
              <div className='w-2/3 mt-8'>
                  <div className="flex items-center justify-between flex-wrap gap-4">
                      <div className="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8" fill="none"><circle cx="7" cy="7" r="3" fill="#9377FB"></circle></svg>
                          <span className="text-black text-xl font-light uppercase">Seller</span>

                      </div>
                  </div>
              </div>

              {/* <div className='w-1/3 mt-8 flex justify-end'>
                  <div className="flex flex-end space-x-2">
                      <div className="relative inline-block text-left">
                          <div className='flex w-full bg-white py-2 px-4 rounded-lg border-gray-200'>
                              <p className='ml-2 mr-6 '>Today</p>
                              <button
                                  type="button"
                                  className="inline-flex items-center justify-center w-6 h-6 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
                                  onClick={toggleDropdown}
                              >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                  >
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                  </svg>
                              </button>
                          </div>

                          {isOpen && (
                              <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                      <a
                                          href="#"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          role="menuitem"
                                      >
                                          Option 1
                                      </a>
                                      <a
                                          href="#"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          role="menuitem"
                                      >
                                          Option 2
                                      </a>
                                      <a
                                          href="#"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          role="menuitem"
                                      >
                                          Option 3
                                      </a>
                                  </div>
                              </div>
                          )}
                      </div>
                  </div>
              </div> */}
            </div>

            <div className="grid xl:grid-cols-5 lg:grid-cols-5 sm:grid-cols-2 grid-cols-2 xl:gap-[15px] gap-4">
              {data?.map((item, key) => (
                <Link
                  key={key}
                  to={"/creator-profile/" + item?.wallets[0]?.address}
                >
                  <CreatorCard
                    banner={item.bg_image}
                    profile={item.avatar_url}
                    username={item.username}
                    follower={item.follower_list}
                    isVerify={item.isVerified}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }

  return null;
};

const FeatureBox = () => {
  const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
  const featureData = [
    {
      name: "Set up your wallet",
      description:
        " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
      icon: <BsWallet2 size={25} color="white" />,
      iconFeature: <BsWallet2 />,
    },
    {
      name: "Add your NFT's",
      description:
        " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
      icon: <AiOutlineCloudUpload size={25} color="white" />,
      iconFeature: <BsWallet2 />,
    },
    {
      name: "Sell your NFT's",
      description:
        " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
      icon: <IoPricetags size={25} color="white" />,
      iconFeature: <BsWallet2 />,
    },
  ];
  return (
    <>
      {" "}
      {featureData.map((item, key) => (
        <>
          <div
            key={key}
            className="bg-[#ffff] px-[50px] py-[40px] relative "
            style={{ boxShadow: "0 0 8px 0 rgb(0 0 0 / 30%)" }}
          >
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={0}
              duration={600}
              triggerOnce
            >
              <div className="flex mb-5">
                <div className="rounded-md bg-primaryPink inline-flex text-left p-3">
                  {item.icon}
                </div>
              </div>{" "}
            </Reveal>

            <div className="text-black text-left">
              <div className="">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="font-bold mb-2">{item.name}</h4>
                </Reveal>
              </div>
              <div className="">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                >
                  <p className="text-[#727272] text-[17px]">
                    {item.description}
                  </p>
                </Reveal>
              </div>
            </div>
            {/* <span className="absolute -rotate-[30deg] text-[140px] bg-transparent w-auto h-auto right00 top-[30%]">
              {item.iconFeature}
            </span> */}
          </div>
        </>
      ))}
    </>
  );
};
export default HomePage;
