import React from "react";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import NFTCard from "../Cards/NFTCard";
function ProfileNfts({ nfts }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <Tab.Group>
        <Tab.List className=" flex space-x-1 rounded-md --bg-blue-900/20 p-1 max-w-[500px] w-full">
          {["All", "Auction", "Not Approved"].map((indexedDB, index) => (
            <>
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-400 focus:outline-none ",
                    selected
                      ? "bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white rounded-lg text-sm"
                      : "text-black font-normal bg-white mx-1.5 text-sm"
                  )
                }
              >
                {indexedDB}
              </Tab>
            </>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-[50px]">
          <Tab.Panel>
            <div className="">
              <div className="grid sm:grid-cols-3  md:grid-cols-6 gap-5 ">
                {nfts?.user?.nfts?.map((item, key) => (
                  <Link
                    key={key}
                    to={`/asset/binance/${item.contractAddress}/${item.tokenId}`}
                  >
                    <NFTCard
                      name={item.name}
                      price={item.price}
                      chain={item.chainId}
                      image={item.imageUrl}
                      category={item.category}
                      owner={{
                        username: nfts?.user?.username,
                        avatar_url: nfts?.user?.avatar_url,
                        isVerified: nfts?.user?.isVerified,
                      }}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            {" "}
            <div className="">
              <div className="grid sm:grid-cols-3  md:grid-cols-4  lg:grid-cols-6 gap-5 ">
                {nfts?.user?.nfts?.map(
                  (item, key) =>
                    item.isAuction && (
                      <Link
                        key={key}
                        to={`/asset/binance/${item.contractAddress}/${item.tokenId}`}
                      >
                        <NFTCard
                          name={item.name}
                          price={item.price}
                          chain={item.chainId}
                          image={item.imageUrl}
                          category={item.category}
                          owner={{
                            username: nfts?.user?.username,
                            avatar_url: nfts?.user?.avatar_url,
                            isVerified: nfts?.user?.isVerified,
                          }}
                        />
                      </Link>
                    )
                )}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            {" "}
            <div className="">
              <div className="grid sm:grid-cols-3  md:grid-cols-4  lg:grid-cols-6 gap-5 ">
                {nfts?.user?.nfts?.map(
                  (item, key) =>
                    !item.isApproved && (
                      <Link
                        key={key}
                        to={`/asset/binance/${item.contractAddress}/${item.tokenId}`}
                      >
                        <NFTCard
                          name={item.name}
                          price={item.price}
                          chain={item.chainId}
                          image={item.imageUrl}
                          category={item.category}
                          owner={{
                            username: nfts?.user?.username,
                            avatar_url: nfts?.user?.avatar_url,
                            isVerified: nfts?.user?.isVerified,
                          }}
                        />
                      </Link>
                    )
                )}
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

export default ProfileNfts;
