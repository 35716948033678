import React, { useContext, useState } from "react";
import { DatePicker, Form, Input, Select, Space, Switch, Upload } from "antd";
import { FiMinusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { LoadingContext } from "../Provider/LoadingProvider";
import useStorage from "../hooks/useStorage";
import useERC721 from "../hooks/useERC721";
import { useMutation, useQuery } from "@apollo/client";
import { CreateNFT } from "../graphql/mutation";
import { ChainsInfo } from "../config/config-chains";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import { toDecimal } from "../utils";
import { UserInfo } from "../graphql/query";
import { STEP } from "../config/constant/url";
import { MINT_ALERT } from "../config/constant/alert";
import useSignature from "../hooks/useSignature";
import { Tooltip } from "react-tippy";
import { MdVerified } from "react-icons/md";
import { truncateAddress } from "../utils";
import {
  Globe,
  GripHorizontal,
  Twitter,
  Instagram,
  Facebook,
} from "lucide-react";

function CreatePage() {
  const [previewImage, setPreviewImage] = useState(null);
  const [isAuction, setAuction] = useState(false);
  const [isLazyMinting, setLazyMint] = useState(false);
  const { uploadOnIpfs, downloadJSONOnIpfs } = useStorage();
  const { genrateSignature } = useSignature();
  const { address, chain, isConnected, handleModal } =
    useContext(WalletConnectContext);
  const { hideLoading, showLoading } = useContext(LoadingContext);
  const { mint } = useERC721();
  const [createNFT] = useMutation(CreateNFT);
  const { data: userInfo } = useQuery(UserInfo, {
    skip: !address,
    variables: {
      walletAddress: address,
    },
  });

  //console.log(userInfo?.user?.avatar_url);
  const PropertiesForm = () => {
    return (
      <>
        {" "}
        <div className="my-3">
          <Form.List name="attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      alignItems: "center",
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "trait_type"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Key parameters",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        placeholder="trait_type"
                        className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Values",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        placeholder="value"
                        className="text-[#ffffff] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                      />
                    </Form.Item>
                    <FiMinusCircle color="red" onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <span
                    onClick={() => add()}
                    //className="cursor-pointer rounded-md  bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white py-1 px-2 text-[12px]"
                    className="mt-4 shadow-2xl py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                  >
                    Add Properties
                  </span>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="bg-blue-50">
        <div className="absolute top-0 left-0 w-full lg:h-16 h-14 bg-cover bg-bottom  lg:bg-[url('/assets/images/ProfileBh.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0] px-4"></div>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-4 mb-8">
        <div className=" pt-2">
          <div className="container row lg:flex block gap-4">
            <div className="@responsive xl:w-1/4 lg:w-2/5 w-full">
              <div className="bg-white p-4 shadow-2xl rounded-md">
                <div>
                  <img
                    className="h-auto rounded-full mx-auto"
                    src={
                      userInfo
                        ? userInfo?.user?.avatar_url
                        : "https://www.pngitem.com/pimgs/m/504-5040528_empty-profile-picture-png-transparent-png.png"
                    }
                    alt=""
                    width="auto"
                  />

                  <div className="text-center pt-2">
                    <h3 className="font-medium text-lg">
                      {userInfo
                        ? userInfo?.user?.firstname +
                          " " +
                          userInfo?.user?.lastname
                        : "Unammed"}
                    </h3>
                    <p className="mb-1 text-sm text-gray-500">
                      @{userInfo ? userInfo?.user?.username : "unnammed"}
                    </p>
                  </div>

                  <p className="py-2 text-gray-400 text-sm">
                    {userInfo?.user?.about_details}
                  </p>

                  <div className="flex gap-4 items-center justify-center">
                    <div className="text-center">
                      <h3 className="mb-0 font-bold text-black">
                        {userInfo?.user?.nfts?.length}
                      </h3>
                      <h6 className="mb-0 text-sm font-semibold text-gray-400">
                        NFT
                      </h6>
                    </div>{" "}
                    <div className="text-center">
                      <h3 className="mb-0 font-bold text-black">
                        {userInfo?.user?.follower_list?.length}
                      </h3>
                      <h6 className="mb-0 text-sm font-semibold text-gray-400">
                        Followers
                      </h6>
                    </div>{" "}
                    <div className="text-center">
                      <h3 className="mb-0 font-bold text-black">
                        {" "}
                        {userInfo?.user?.following_list?.length}
                      </h3>
                      <h6 className="mb-0 text-sm font-semibold text-gray-400">
                        Following
                      </h6>
                    </div>
                  </div>

                  <ul
                    className="list-none sidebar-nav mb-0 mt-3"
                    id="navmenu-nav"
                  >
                    <Link to="/profile" className="cursor-pointer">
                      <li className="navbar-item account-menu text-[16px] navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span className="mr-2 mb-0">
                          <i className="uil uil-dashboard" />
                        </span>
                        <h6 className="mb-0 font-medium">Profile</h6>
                      </li>
                    </Link>
                    <Link to="/edit-profile" className="">
                      <li className="navbar-item account-menu text-[16px] active navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span className="mr-2 mb-0">
                          <i className="uil uil-browser" />
                        </span>
                        <h6 className="mb-0 font-medium">Edit Profile</h6>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            <div className="@responsive xl:w-3/4 lg:w-3/5 w-full">
              <Form
                layout="vertical"
                initialValues={{
                  name: "NFT 0001",
                  description: "NFT 0001 is the name of the project",
                  category: "art",
                  price: 2,
                  royalty: 5,
                }}
                onFinish={async (values) => {
                  showLoading();
                  let metadata = {
                    name: values.name,
                    description: values.description,
                    image: values.file.file.originFileObj,
                    external_url: "",
                    background_color: "",
                    attributes: values.attributes,
                  };
                  if (
                    values.file.file.type.match(/(?:image|video)/g)[0] ===
                    "video"
                  ) {
                    metadata.animation_url = values.file.file.originFileObj;
                    metadata.image =
                      "https://res.cloudinary.com/practicaldev/image/fetch/s--0QbcuqxC--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://s3.ap-south-1.amazonaws.com/afteracademy-server-uploads/mastering-mongoose-for-mongodb-and-node-js-mongoose-diagram-77560014632570f4.png";
                  }
                  let uri = await uploadOnIpfs(metadata);
                  let url = await downloadJSONOnIpfs(uri);
                  console.log(url, uri);
                  let tx = await mint(12, uri)
                    .then()
                    .catch((err) => {
                      console.log(err);
                      hideLoading();
                    });
                  console.log("...tx.....");
                  console.log(tx);
                  let conformation = await tx.wait();
                  console.log(conformation);
                  createNFT({
                    variables: {
                      tokenId: toDecimal(
                        conformation?.events[0].args.tokenId._hex
                      ),
                      name: values.name,
                      ipfsUrl: uri,
                      imageUrl: url?.image,
                      chainId: chain?.id,
                      network: ChainsInfo[chain?.id]?.CHAIN_NAME,
                      ownerAddress: address,
                      creatorAddress: address,
                      contractAddress: ChainsInfo[chain?.id]?.NFT_ADDRESS,
                      category: values.category,
                    },
                  })
                    .then((response) => {
                      console.log(response);
                      MINT_ALERT();
                    })
                    .catch(() => {
                      console.log("error");
                    });
                  // genrateSignature(address, "100000", 500, address, "uri", address)
                  //   .then((res) => {
                  //     console.log(res);
                  //     hideLoading();
                  //   })
                  //   .catch((err) => {
                  //     console.log(err);
                  //     hideLoading();
                  //   });
                  hideLoading();
                }}
                className="lg:col-span-9 md:col-span-8"
              >
                <div className="bg-white p-4 shadow-2xl py-8  rounded-lg">
                  <div className="lg:flex block gap-4">
                    <div className="flex-initial lg:w-2/5 w-full">
                      <div className="border border-gray-200 rounded-md p-2 py-4">
                        <p className="text-sm">
                          Upload your ART here, Please click “Upload Image”
                          Button
                        </p>
                        <div>
                          <div className="bg-blue-100 py-4 p-4 my-4 rounded-lg h-64 content-center">
                            {!previewImage ? (
                              <p className="text-center text-sm">
                                Upload NFT and Supports JPG, PNG and MP4 videos
                              </p>
                            ) : (
                              <img
                                src={previewImage}
                                //className="absolute bg-[#ff999e] p-5 rounded-md h-full  top-0 left-0 bottom-0 right-0"
                                className="bg-[#ff999e] rounded-md h-full  top-0 left-0 bottom-0 right-0"
                              />
                            )}
                          </div>
                          <Form.Item
                            name="file"
                            className="text-black #ff999e"
                            rules={[
                              {
                                required: true,
                                message: "Upload your art",
                              },
                            ]}
                          >
                            <Upload
                              onChange={(file) => {
                                console.log(file.file.originFileObj);
                                setPreviewImage(
                                  URL.createObjectURL(file.file.originFileObj)
                                );
                              }}
                              name="file"
                              style={{ width: "100% !important " }}
                            >
                              <div>
                                <div>
                                  <button className=" shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200">
                                    Click to Upload
                                  </button>
                                </div>
                              </div>
                            </Upload>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="flex-initial lg:w-3/5 w-full">
                      <Form.Item
                        label="Title"
                        name="name"
                        className="text-black"
                        rules={[
                          {
                            required: true,
                            message: "NFT title is missing!",
                          },
                        ]}
                      >
                        <input
                          type="text"
                          placeholder="Title"
                          className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-black border border-black  "
                        />
                      </Form.Item>
                      {!isAuction && (
                        <Form.Item
                          label="Price"
                          name="price"
                          className="text-black"
                          rules={[
                            {
                              required: true,
                              message: "Price is missing!",
                            },
                          ]}
                        >
                          <input
                            type="number"
                            step={STEP}
                            min={STEP}
                            placeholder="Enter the price"
                            className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-black border border-black"
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        label="Category"
                        name="category"
                        className="text-black"
                        rules={[
                          {
                            required: true,
                            message: "Select one category",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Category"
                          className="outline-none black"
                          options={[
                            {
                              value: "art",
                              label: "Art",
                            },
                            {
                              value: "music",
                              label: "Music",
                            },
                            {
                              value: "sports",
                              label: "Sports",
                            },
                            {
                              value: "utility",
                              label: "Utility",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                      {/* //For Auction */}

                      <div className="flex items-center justify-between my-3 ">
                        <div className="text-black">
                          <h3 className="mb-0 font-bold  text-base ">
                            Lazy Minting
                          </h3>
                          <p className="mb-0 text-[12px]">
                            Users can mint nft for free
                          </p>
                        </div>
                        <div>
                          <Switch
                            onChange={(checked) => setLazyMint(checked)}
                            className="outline-black border border-black bg-black"
                          />
                        </div>
                      </div>
                      {isAuction && (
                        <>
                          {" "}
                          <Form.Item
                            label="Minium Bid"
                            name="bid_amount"
                            className="text-black"
                            rules={[
                              {
                                required: true,
                                message: "Min bid price is missing!",
                              },
                            ]}
                          >
                            <input
                              type="number"
                              step={STEP}
                              min={STEP}
                              placeholder="Enter the price"
                              className="text-[#ffffff] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Expire Date"
                            name="end_date"
                            className="text-black"
                            rules={[
                              {
                                required: true,
                                message: "Pick end date of auction!",
                              },
                            ]}
                          >
                            <DatePicker showTime />
                          </Form.Item>
                        </>
                      )}
                      <div className="flex items-center justify-between my-3 ">
                        <div className="text-black">
                          <h3 className="mb-0 font-bold  text-base ">
                            Auction Enable
                          </h3>
                          <p className="mb-0 text-[12px]">
                            Set a period of time for which buyers can place bids
                          </p>
                        </div>
                        <div>
                          <Switch
                            onChange={(checked) => setAuction(checked)}
                            className="outline-black border border-black bg-black"
                          />
                        </div>
                      </div>

                      <Form.Item
                        label="Royalties"
                        name="royalty"
                        className="text-black"
                        rules={[
                          {
                            required: true,
                            message: "Enter royalties between 0% to 10%",
                          },
                        ]}
                      >
                        <input
                          type="number"
                          step={STEP}
                          min={STEP}
                          max={10}
                          placeholder="Enter the royalties "
                          className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-black border border-black"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Write the short description of the item",
                          },
                        ]}
                      >
                        <textarea
                          type="text"
                          maxLength={500}
                          placeholder="Description"
                          className="text-black h-[100px] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-black border border-black"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Unlock Content"
                        name="unlock_content"
                        className="text-black"
                      >
                        <input
                          type="text"
                          placeholder="Paste link , Unlock Content, text"
                          className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-black border border-black"
                        />
                      </Form.Item>
                      {/* <AddProperty /> */}
                      <PropertiesForm />
                    </div>
                  </div>
                </div>
                <div className=" col-start-11 col-end-13 ">
                  {!isConnected && (
                    <div
                      className="mt-4 py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                      onClick={() => handleModal(true)}
                    >
                      Connect Wallet
                    </div>
                  )}
                  {isConnected && (
                    <button className="mt-4 py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200">
                      Create Item
                    </button>
                  )}
                </div>{" "}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePage;
