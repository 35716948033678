import React from "react";
import { ChainsInfo } from "../../config/config-chains";

function NFTCard({ name, price, chain, image, owner, category }) {
  return (
    <div>

      <div className="bg-white col-span-12 md:col-span-6 lg:col-span-3 2xl:col-span-2 p-1 rounded-md shadow-xl pb-2">
        <div className="text-left">
          <img className="h-auto" src={image} alt="" width="auto"/>
          <div className='p-2'>
            <p className="text-black">{name}</p>
            <p className="text-purple-600 text-sm">{category}</p>
          </div>
          <div className="border-t-2 border-gray-100 px-2 py-2">
            <p className="text-pink-600  text-sm">{price}{" "}
              {ChainsInfo[chain]?.CURRENCY_SYMBOL}</p>
          </div>
          <div>
            <div className="flex items-center gap-4 bg-gray-100 rounded-full p-2">
              <img className="w-6 h-6 rounded-full" src={owner?.avatar_url} alt={owner?.username} />
              <div className="font-medium dark:text-white">
                <p className='text-black font-light text-sm'> Owner of <span className="font-bold" text-black>@{owner?.username}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default NFTCard;
