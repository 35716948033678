export const ChainsInfo = {
  97: {
    NETWORK_NAME: "Binance Testnet",
    CHAIN_NAME: "binance",
    CURRENCY_SYMBOL: "BNB",
    NFT_MARKETPLACE_ADDRESS: "0x2d299281d4fe2D6fD587a815C37602d6684B2bDA",
    NFT_ADDRESS: "0x56062516fE73a5C848F40e1E955826239003D9bF",
    WRAP_TOKEN: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg",
    CHAIN_ID: "97",
    BLOCK_EXPLORER_URL: "https://testnet.bscscan.com",
    RPC_PROVIDER_URL: "https://data-seed-prebsc-2-s3.binance.org:8545",
  },
};
