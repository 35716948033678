import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiChevronUpDown } from "react-icons/hi2";
import { BsCheck } from "react-icons/bs";

export default function DropdownBox({ data, filterInfo, setFilterInfo }) {
  const [selected, setSelected] = useState("");

  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        setSelected(value);
        setFilterInfo(value.value);
      }}
    >
      <div className="relative ">
        <Listbox.Button className="relative cursor-default rounded-lg border border-black w-[150px] py-2  px-10 w-full h-full  text-left shadow-md ">
          <span className="block truncate text-black text-center">
            {selected === "" ? data[0].name : selected?.name}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiChevronUpDown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute  mt-1 max-h-60 w-full  overflow-auto rounded-md bg-[#ffffff] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {data.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white" : "text-black"
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {item.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <BsCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
