import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect } from "react";
import { WalletConnectContext } from "../../Provider/WalletConnectProvider";
import { RxCross2 } from "react-icons/rx";
import { SignIn } from "../../graphql/query";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
export default function WalletConnetModal() {
  const {
    isOpen,
    handleModal,
    connectors,
    connectAsync,
    pendingConnector,
    isLoading,
    setIsAuthenticated,
    address,
    isConnected,
  } = useContext(WalletConnectContext);
  const navigate = useNavigate();
  const { data: signIn, loading } = useQuery(SignIn, {
    skip: !address,
    variables: {
      walletAddress: address,
    },
  });
  useEffect(() => {
    if (isConnected && !loading) {
      if (!signIn?.signIn) {
        handleModal(false);
        setIsAuthenticated(true);
      } else {
        handleModal(false);
        setIsAuthenticated(false);
      }
    }
  }, [isConnected, loading, address]);

  const walletIcon = {
    metaMask: {
      title: "Metamask",
      icon: "https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg",
    },
    walletConnect: {
      title: "WalletConnect",
      icon: "https://workablehr.s3.amazonaws.com/uploads/account/open_graph_logo/492879/social?1667288171000",
    },
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-4 py-10 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white rounded-2xl bg-primaryWhite px-6 py-10 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-3xl mb-5 font-bold leading-6 text-black"
                  >
                    <span>Connect Wallet</span>
                    <div className="absolute top-0 w-[95%] right-0 flex justify-end p-5 ">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleModal(false)}
                      >
                        <RxCross2 size={20} className="" />
                      </span>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base text-[#ffffff99]">
                      Choose how you want to connect. There are several wallet
                      providers.
                    </p>
                  </div>

                  <div className="mt-4">
                    <div className="flex flex-col gap-4">
                      {connectors.map((value, key) => (
                        <div
                          key={key}
                          className="flex-1 border border-[#ffffff14] rounded-md"
                        >
                          <button
                            type="button"
                            disabled={!value.ready}
                            className="h-[52px] w-full rounded-lg py-2.5 px-5 text-md font-semibold text-black flex items-center gap-5   bg-primaryWhite shadow"
                            onClick={async () => {
                              connectAsync({ connector: value })
                                .then(async (res) => {
                                  console.log(res);
                                  handleModal(false);
                                })
                                .catch((err) => {
                                  console.log(err);
                                  handleModal(false);
                                });
                              console.log(signIn);
                            }}
                          >
                            <div className="flex items-center gap-4 flex-1">
                              <span>
                                <img
                                  width={"28px"}
                                  src={walletIcon[value.id]?.icon}
                                />
                              </span>
                              <span className="tracking-wide text-black">
                                {walletIcon[value.id]?.title}
                              </span>
                            </div>
                            {isLoading && value.id === pendingConnector?.id && (
                              <span className="loader"></span>
                            )}
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/");
                          handleModal(false);
                        }}
                        className="h-[52px] w-full rounded-lg py-2.5 px-5 text-md font-semibold text-black flex justify-center items-center gap-5   bg-primaryPink shadow"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
