import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { Button, Checkbox, Form, Input } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { SignUp } from "../../graphql/mutation";
import { SignIn } from "../../graphql/query";

import { WalletConnectContext } from "../../Provider/WalletConnectProvider";

function AuthModal() {
  const { disconnect, isAuthenticated, setIsAuthenticated, address } =
    useContext(WalletConnectContext);
  const [isSubmit, setSubmit] = useState(false);
  const [signUp] = useMutation(SignUp);
  return (
    <div className="">
      {" "}
      <Transition appear show={isAuthenticated} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => console.log("")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-primaryWhite">
            <div className="flex min-h-full items-center justify-center px-4 py-10 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-gray-200 w-full  border border-[#ffffff1a]  max-w-sm transform overflow-hidden rounded-2xl bg-primaryWhite px-7 py-[30px] text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-3xl mb-5 font-bold leading-6 text-black"
                  >
                    <span>Create Account</span>
                    <div className="absolute top-0 w-[95%] right-0 flex justify-end p-5 ">
                      {" "}
                    </div>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base --text-[#ffffff99]">
                      You can set your user name abd add your email
                      address(optional).
                    </p>
                  </div>

                  <div className="mt-4">
                    <div className="flex flex-col gap-4">
                      {" "}
                      <Form
                        onFinish={(values) => {
                          setSubmit(true);
                          console.log(values);
                          signUp({
                            variables: {
                              walletAddress: address,
                              lastname: values.lastname,
                              firstname: values.firstname,
                              username: values.username,
                            },
                            refetchQueries: [
                              {
                                query: SignIn,
                                variables: {
                                  walletAddress: address,
                                },
                              },
                            ],
                          })
                            .then((res) => {
                              console.log(res);
                              setIsAuthenticated(false);
                              setSubmit(false);
                            })
                            .catch((error) => {
                              console.log(error);
                              setIsAuthenticated(false);
                              setSubmit(false);
                            });
                        }}
                        layout="vertical"
                        autoComplete="off"
                      >
                        <Form.Item
                          label="Username"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            placeholder="Username"
                            className="---text-[#ffffff] ---bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                          />
                        </Form.Item>
                        <Form.Item
                          label="First Name"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            placeholder="First Name"
                            className="---text-[#ffffff] ---bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            placeholder="Last Name"
                            className="---text-[#ffffff] ---bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                          />
                        </Form.Item>

                        <Form.Item label="Email Address" name="email">
                          <input
                            type="text"
                            placeholder="Enter email address (optional)"
                            className="---text-[#ffffff] ---bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                          />
                        </Form.Item>

                        <Form.Item
                          name="accept"
                          valuePropName="checked"
                          rules={[
                            {
                              required: true,
                              message: "Check terms and conditions",
                            },
                          ]}
                        >
                          <Checkbox>I read all Terms and Conditions.</Checkbox>
                        </Form.Item>

                        <Form.Item>
                          <button
                            htmlType="submit"
                            disabled={isSubmit}
                            className=" mt-5 w-full  font-bold bg-primaryPink text-black  p-3 rounded-lg"
                          >
                            {isSubmit ? (
                              <div className="rounded-lg flex items-center">
                                <span className="loader"></span>
                              </div>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>

                          {!isSubmit && (
                            <div
                              onClick={() => {
                                disconnect();
                                setIsAuthenticated(false);
                              }}
                              className=" text-center cursor-pointer  mt-3 w-full  font-bold bg-[#ffffff1a] text-black  p-3 rounded-lg"
                            >
                              Disconnect
                            </div>
                          )}
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default AuthModal;
