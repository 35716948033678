import {
  getErc721Contract,
  getMarketplaceContract,
} from "../utils/contractHelper";
import { ethers } from "ethers";
import React, { useContext } from "react";
import { ChainsInfo } from "../config/config-chains";
import {
  DEFAULT_CHAIN_ID,
  DEFAULT_NATIVE_TOKEN_ADDRESS,
} from "../config/constant/url";
import { useAccount, useSigner } from "wagmi";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import { resolve } from "url";
import { calculateGasMargin, toDecimal } from "../utils";

function useERC721() {
  const { provider, chain } = useContext(WalletConnectContext);
  const { address } = useAccount();
  const { data: signer } = useSigner();
  let p = new ethers.providers.Web3Provider(window.ethereum);

  const mint = async (royalty, uri) => {
    //alert("In mint");
    let s = p.getSigner();
    //console.log("His signer: ->>>>", signer);
    //console.log("My signer: ->", s);
    let cont = getErc721Contract(
      "0x56062516fE73a5C848F40e1E955826239003D9bF",
      s
    );
    //console.log("received erc721 is: ---------> ", cont);
    return await getErc721Contract(
      "0x56062516fE73a5C848F40e1E955826239003D9bF",
      s
    ).mint(
      [
        address,
        address,
        address,
        royalty * 100,
        address,
        uri,
        "0",
        "0x0000000000000000000000000000000000000000",
        "0",
        "0",
        "0x64616f204e465400000000000000000000000000000000000000000000000000",
      ],
      {
        from: address,
      }
    );
  };
  const approve = async (tokenId) => {
    //console.log("inside the approve function");
    const gasPrice = calculateGasMargin(
      await getErc721Contract(
        ChainsInfo[chain?.id].NFT_ADDRESS,
        signer
      ).estimateGas.approve(
        ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
        tokenId
      )
    );

    return await getErc721Contract(
      ChainsInfo[chain?.id].NFT_ADDRESS,
      signer
    ).approve(ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS, tokenId, {
      from: address,
      gasLimit: gasPrice,
    });
  };
  const tokenURI = (tokenId) => {
    return getErc721Contract(
      ChainsInfo[DEFAULT_CHAIN_ID].NFT_ADDRESS,
      new ethers.providers.JsonRpcProvider(
        ChainsInfo[DEFAULT_CHAIN_ID].RPC_PROVIDER_URL
      )
    ).tokenURI(tokenId);
  };
  const ownerOf = async (tokenId) => {
    return await getErc721Contract(
      ChainsInfo[DEFAULT_CHAIN_ID].NFT_ADDRESS,
      new ethers.providers.JsonRpcProvider(
        ChainsInfo[DEFAULT_CHAIN_ID].RPC_PROVIDER_URL
      )
    ).ownerOf(tokenId);
  };
  const listings = async (listingId) => {
    return await getMarketplaceContract(
      ChainsInfo[DEFAULT_CHAIN_ID].NFT_MARKETPLACE_ADDRESS,
      new ethers.providers.JsonRpcProvider(
        ChainsInfo[DEFAULT_CHAIN_ID].RPC_PROVIDER_URL
      )
    ).listings(listingId);
  };
  const buy = async (listingId, price) => {
    let contract = getMarketplaceContract(
      ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
      signer
    );

    let gas = await contract.estimateGas.buy(
      listingId,
      address,
      1,
      DEFAULT_NATIVE_TOKEN_ADDRESS,
      price,
      {
        from: address,
        value: price,
      }
    );

    return await contract.buy(
      listingId,
      address,
      1,
      DEFAULT_NATIVE_TOKEN_ADDRESS,
      price,
      {
        from: address,
        value: price,
        gasLimit: calculateGasMargin(gas),
      }
    );
  };
  const createListing = async (
    tokenId,
    price,
    reservePricePerToken,
    currencyTokenPrice,
    ListingType = 0
  ) => {
    console.log(chain?.id);
    const gasPrice = calculateGasMargin(
      await getMarketplaceContract(
        ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
        signer
      ).estimateGas.createListing(
        [
          ChainsInfo[chain?.id].NFT_ADDRESS,
          tokenId,
          parseInt(new Date().getTime() / 1000).toString(),
          86400,
          1,
          currencyTokenPrice,
          new ethers.utils.parseEther(reservePricePerToken).toString(),
          new ethers.utils.parseEther(price).toString(),
          ListingType, // 0 ->Direct Listing and 1-> Auction
        ],
        {
          from: address,
        }
      )
    );

    console.log(toDecimal(gasPrice._hex));

    return await getMarketplaceContract(
      ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
      signer
    ).createListing(
      [
        ChainsInfo[chain?.id].NFT_ADDRESS,
        tokenId,
        parseInt(new Date().getTime() / 1000).toString(),
        86400,
        1,
        currencyTokenPrice,
        new ethers.utils.parseEther(reservePricePerToken).toString(),
        new ethers.utils.parseEther(price).toString(),
        ListingType, // 0 ->Direct Listing and 1-> Auction
      ],
      {
        from: address,
        gasLimit: gasPrice,
      }
    );
  };

  const cancelDirectListing = async (listingId) => {
    const gasPrice = calculateGasMargin(
      await getMarketplaceContract(
        ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
        signer
      ).estimateGas.cancelDirectListing(listingId, { from: address })
    );
    console.log(gasPrice);
    return await getMarketplaceContract(
      ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
      signer
    ).cancelDirectListing(listingId, {
      from: address,
      gasLimit: gasPrice,
    });
  };

  const closeAuction = async (listingId) => {
    const gasPrice = calculateGasMargin(
      await getMarketplaceContract(
        ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
        signer
      ).estimateGas.closeAuction(listingId, address, { from: address })
    );

    return await getMarketplaceContract(
      ChainsInfo[chain?.id].NFT_MARKETPLACE_ADDRESS,
      signer
    ).closeAuction(listingId, address, {
      from: address,
      gasLimit: gasPrice,
    });
  };

  return {
    mint,
    tokenURI,
    createListing,
    listings,
    buy,
    approve,
    ownerOf,
    closeAuction,
    cancelDirectListing,
  };
}

export default useERC721;

// ["0x56062516fE73a5C848F40e1E955826239003D9bF","15","1672137188","1872132148","1","0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE","0","100000000000000","0"]
// ["0x56062516fE73a5C848F40e1E955826239003D9bF","16","1672204908",86400,1,"0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE","0","1000000000000000",0]
