import { ethers } from "ethers";
import { useContext } from "react";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
function useSignature() {
  const { provider, chain } = useContext(WalletConnectContext);
  const genrateSignature = async (
    nftCreator,
    minPrice,
    royaltyFee,
    royaltyWalletAddress,
    uri,
    currency
  ) => {
    const SIGNING_DOMAIN_NAME = "LazyNFT-Voucher";
    const SIGNING_DOMAIN_VERSION = "1";

    const providerLazy = new ethers.providers.Web3Provider(provider);
    const signer = providerLazy.getSigner();

    const voucher = {
      nftCreator,
      minPrice,
      royaltyFee,
      royaltyWalletAddress,
      uri,
      currency,
    };
    const domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: "0x026fF2661Af5890A3Fb5B87D7d43dCc93B858632",
      chainId: 80001,
    };
    const types = {
      NFTVoucher: [
        { name: "nftCreator", type: "address" },
        { name: "minPrice", type: "uint256" },
        { name: "royaltyFee", type: "uint256" },
        { name: "royaltyWalletAddress", type: "address" },
        { name: "uri", type: "string" },
        { name: "currency", type: "address" },
      ],
    };
    const signature = await signer._signTypedData(domain, types, voucher);

    return {
      ...voucher,
      signature,
    };
  };
  return {
    genrateSignature,
  };
}

export default useSignature;
