import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { Rate } from "antd";
import { Fragment, useEffect, useState } from "react";
import { SetRating } from "../../graphql/mutation";
import { GetNft, GetRating } from "../../graphql/query";

export default function RatingModal({
  isOpen,
  setIsOpen,
  userId,
  nftId,
  contractAddress,
  tokenId,
}) {
  const [reviews, setReviews] = useState({
    rate: 1,
    comment: "",
  });

  const [setRating] = useMutation(SetRating);
  const [getRating, { data: useRating }] = useLazyQuery(GetRating);
  useEffect(() => {
    console.log({ userId: userId, nftId: nftId });
    getRating({
      variables: {
        userId: userId,
        nftId: nftId,
      },
    }).then((rating) => {
      console.log(rating?.data);
      setReviews({
        rate: rating.data?.getRating?.rating,
        comment: rating.data?.getRating?.comment,
      });
    });
  }, [userId, nftId]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform bg-white overflow-hidden rounded-2xl bg-primaryWhite p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-black  font-bold  leading-6 "
                  >
                    Rating
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm ">
                      <Rate
                        defaultValue={useRating?.getRating?.rating}
                        onChange={(value) => {
                          setReviews({ rate: value, comment: reviews.comment });
                        }}
                      />
                      <span className="ml-4 text-black  font-semibold">
                        {reviews.rate} star
                      </span>
                    </p>
                  </div>
                  <div className="mt-2">
                    <textarea
                      type="text"
                      maxLength={500}
                      defaultValue={reviews.comment}
                      onChange={(e) =>
                        setReviews({
                          comment: e.target.value.trim(),
                          rate: reviews.rate,
                        })
                      }
                      placeholder="Write your commnet "
                      className="text-[#ffffff] h-[100px] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="capitalize inline mt-5 font-bold bg-primaryPink text-black px-5 py-2 rounded-lg"
                      onClick={() => {
                        setRating({
                          variables: {
                            rating: reviews.rate,
                            user: userId,
                            nftId: nftId,
                            comment: reviews.comment ? reviews.comment : "",
                          },
                          refetchQueries: [
                            {
                              query: GetNft,
                              variables: {
                                contractAddress: contractAddress,
                                tokenId: parseInt(tokenId),
                              },
                            },
                            {
                              query: GetRating,

                              variables: {
                                userId: userId,
                                nftId: nftId,
                              },
                            },
                          ],
                        }).then((rating) => {
                          console.log(rating);
                        });
                        closeModal();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
