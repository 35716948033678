
import React from "react";
import { useQuery } from "@apollo/client";
import { GetStaticPage } from "../../graphql/query";
const HowItWorksPage = () => {

    const { data, loading, error } = useQuery(GetStaticPage, {
        variables: {
          type: 'how_it_works',
        },
    });

    return (
        <>
            <div className='bg-blue-50'>
                <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
                <div className="relative z-[0]  px-4">
                    <div className="container">
                        <div className="row lg:flex block container pt-4">
                            <div className="w-full">
                                {/* <h1 className="text-white text-center text-4xl  font-bold">How it works</h1> */}
                                <h1 className="text-white text-center text-4xl  font-bold">{data?.getStaticPage?.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">
                <div className="container pt-16">
                    <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
                        <div className="w-full text-center">
                            <h1 className="text-2xl font-bold">What is NFT and How it Works</h1>
                            {/* <p className="py-4">NFTs, or Non-Fungible Tokens, have become increasingly popular in the world of digital art and collectibles. But what exactly are NFTs and how do they work? In this post, we'll explore the basics of NFTs and their role in the blockchain ecosystem.</p> */}
                            <p className="py-4">{data?.getStaticPage?.body?.nftAndWorks}</p>
                        </div>
                    </div>

                    <div className="py-5">
                        <img className="h-full w-full rounded-xl border border-white" src="/assets/images/nft-work.svg" alt="" />
                    </div>

                    <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
                        <div className="w-full text-center">
                            <h1 className="text-2xl font-bold">What is an NFT?</h1>
                            {/* <p className="py-4">An NFT is a unique digital asset that is stored on a blockchain. Each NFT has a unique identifier that distinguishes it from other NFTs. Unlike traditional cryptocurrencies, NFTs are not interchangeable or fungible, which means that each NFT has its own specific value and cannot be replaced by another token.</p> */}
                            <p className="py-4">{data?.getStaticPage?.body?.nft}</p>
                        </div>
                    </div>

                    <div className=" row lg:flex block lg:py-4 py-6 rounded-xl bg-white lg:p-4">
                        <div className="w-full text-center py-6 px-6">
                            <h1 className="lg:text-4xl text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-t from-sky-500 to-purple-800">How do NFTs work?</h1>

                            <div className="py-4" dangerouslySetInnerHTML={{ __html: data?.getStaticPage?.body?.nftWorks }} />

                            {/* <p className="py-4">NFTs are created using smart contracts on a blockchain platform like Ethereum. Each NFT contains metadata that describes the unique characteristics of the digital asset it represents, such as the artist, creation date, and edition number. The ownership of an NFT is recorded on the blockchain, which makes it easy to verify the authenticity and ownership of the asset.</p>
                            <p className="py-4">NFTs are bought and sold on NFT marketplaces like OpenSea and Rarible. The price of an NFT is determined by its perceived value and rarity, just like traditional art and collectibles. Once an NFT is purchased, the buyer becomes the owner of the unique digital asset and can sell or trade it on the marketplace.</p> */}
                        </div>
                    </div>

                    <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
                        <div className="w-full text-center">
                            <h1 className="text-2xl font-bold">Conclusion</h1>
                            {/* <p className="py-4">NFTs have opened up new possibilities for artists and collectors to showcase and monetize their digital creations. As the technology evolves and more people enter the NFT space, it</p> */}
                            <p className="py-4">{data?.getStaticPage?.body?.conclusion}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HowItWorksPage;