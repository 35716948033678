import React, { useContext, useEffect, useRef, useState } from "react";
import "./header.css";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import WalletConnetModal from "./Modal/WalletConnetModal";
import AuthModal from "./Modal/AuthModal";
import { SearchNFT } from "../graphql/query";
import { useLazyQuery } from "@apollo/client";
import { ChainsInfo } from "../config/config-chains";
import TransactionModal from "./Modal/TransactionModal";
function Header() {
  const walletModal = useContext(WalletConnectContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchNft] = useLazyQuery(SearchNFT);
  const ref = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const NavLinks = [
    { title: "Home", link: "/" },
    { title: "Explore", link: "/explore" },
    { title: "About", link: "/about" },
    { title: "Create NFT", link: "/create" },
    { title: "Profile", link: "/profile" },
  ];

  const transitionStyles = {
    entering: { opacity: 100 },
    entered: { opacity: 100 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  /* useEffect(() => {
    console.log(document.documentElement.scrollTop);
    function handleScroll(event) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("myHeader").classList.add("sticky");
        document.getElementById("myHeader").classList.add("bg-primaryWhite");
        document.getElementById("myHeader").style.boxShadow =
          "rgb(10 10 10 / 80%) 0px 4px 20px 0px";
      } else {
        document.getElementById("myHeader").classList.remove("sticky");
        document.getElementById("myHeader").classList.remove("bg-primaryWhite");
        document.getElementById("myHeader").style.boxShadow = "none";
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */
  const Search = async (value) => {
    let search = await searchNft({
      variables: {
        key: value,
      },
    });
    if(search?.data != undefined) {
      setSearchData(search?.data?.searchNfts);
    } else {
      setSearchData([]);
    }
    
    //console.log(search?.data?.searchNfts);
  };

  const handleDropDown = (value) => {
    if (value.trim() === "") {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  };
  return (
    <>

    <section className="container">
      <nav className="relative z-10 bg-white text-white mt-6 py-2 rounded-full rounded m-2 shadow-2xl">

        <div className="container px-4 mx-auto">
          <div className="flex items-center relative">
            <Link to="/" className="inline-block text-lg font-bold lg:pl-2 pl-0">
              <img className="h-8" src="/assets/images/logo.png" alt="" width="auto" />
            </Link>
            <div className="xl:hidden ml-auto flex">
              <button className="lg:ml-4 ml-0 mr-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200">
                Connect Wallet
              </button>

              <div className="relative">
                <button className="navbar-burger flex w-10 h-10 items-center justify-center bg-blue-600 rounded-full transition duration-200" onClick={toggleSidebar}>
                  <svg width="24" height="24" viewBox="0 0 24 24" color="#fff" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 12H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M3 6H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M3 18H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </button>
              </div>

            </div>
            <ul className="absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2 hidden xl:flex lg:w-auto lg:space-x-12">

              {NavLinks.map((item, key) => (
                <li key={key}><Link to={item.link} className="inline-block text-sm text-gray-900 hover:text-orange-900 font-medium">{item.title}</Link></li>
              ))}

            </ul>
            <div className="hidden xl:block ml-auto">
              <div className="flex items-center">
                <form className="max-w-md mx-auto">
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                    <input
                      type="search"
                      id="default-search"
                      className="searchbar block  p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search..."
                      required
                      onChange={(e) => {
                        Search(e.target.value);
                        handleDropDown(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="text-white absolute end-2.5 bottom-2 focus:outline-none font-lg rounded-full text-sm px-2  py-2 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF]"
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        color="#fff"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>

                    {/* //reaulst of search  */}
                    <Transition in={showDropdown} timeout={300} className="w-full ">
                      {(state) => (
                        <div
                          style={{ ...transitionStyles[state] }}
                          className={` ${
                            showDropdown
                              ? "transition ease-in duration-300 translate-y-5"
                              : "hidden  transition ease-in duration-300 translate-y-6"
                          }   overflow-hidden  transition ease-in duration-300   text-white px-5  py-[20px] h-[300px] max-w-[400px]   fixed  mt-[300px]  w-full   rounded-md bg-[#1f2226d9] py-1 border border-[#ffffff1c] text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                        >
                          <div className="">
                            <h3 className="px-2 text-base mb-2">NFTs</h3>
                          </div>
                          <div>
                            {searchData != undefined && searchData?.length >0 && searchData.map((item, key) => (
                                <div
                                  key={key}
                                  className=" transition ease-in cursor-pointer  border border-transparent hover:border hover:bg-[#00000033] hover:border-[#ffffff1a] rounded-md  p-2 flex justify-between items-center mb-1"
                                >
                                  <Link
                                    to={`/asset/${
                                      ChainsInfo[item?.chainId].CURRENCY_SYMBOL
                                    }/${ChainsInfo[item?.chainId].NFT_ADDRESS}/${
                                      item?.tokenId
                                    }`}
                                    className="flex justify-between items-center w-full "
                                  >
                                    <div className="flex items-center gap-3  font-semibold">
                                      <div>
                                        <img
                                          src={item.imageUrl}
                                          width={"40px"}
                                          className="rounded-md"
                                        ></img>
                                      </div>{" "}
                                      <div className="">{item.name}</div>
                                    </div>
                                    <div>
                                      <p className="mb-0 text-sm flex gap-1 text-primaryPink font-semibold">
                                        <span>{item.price}</span>
                                        <span>
                                          {ChainsInfo[item.chainId].CURRENCY_SYMBOL}
                                        </span>
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Transition>
                  </div>
                </form>

                {!walletModal.isConnected ? (
                  <button
                    type="button"
                    className="ml-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                    onClick={() => walletModal.handleModal(true)}
                  >
                    Connect Wallet
                  </button>
                ) : (
                  <button
                    type="button"
                    className="ml-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                    onClick={() => walletModal.disconnect()}
                  >
                    Disconnect Wallet
                  </button>
                )}

              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className={`fixed inset-y-0 left-0 w-64 bg-[#2e1a57] shadow-2xl text-white transition-all z-[20] duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="p-4">
          <div className="flex">
            <div className="flex-none w-4/5">
          
              <ul>

                {NavLinks.map((item, key) => (
                  <li key={key}><Link to={item.link} className="py-2 font-light text-white px-2">{item.title}</Link></li>
                ))}
                
              </ul>
            </div>
            <div className="flex-initial w-1/5">
              <button className="text-white focus:outline-none" onClick={toggleSidebar}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>

          </div>

        </div>
        {/* Other sidebar content goes here */}
      </div>

    </section>

      <WalletConnetModal />
      <TransactionModal />
      <AuthModal />
    </>
  );
}

export default Header;
