import React, { createContext, useState } from "react";
export const PutMarketplaceContext = createContext();

function PutMarketplace({ children }) {
  const [openMarketplace, setMarketplace] = useState(false);

  return (
    <PutMarketplaceContext.Provider
      value={{
        setMarketplace: setMarketplace,
        openMarketplace: openMarketplace,
      }}
    >
      {children}
    </PutMarketplaceContext.Provider>
  );
}

export default PutMarketplace;
