import React, { Fragment, useState } from "react";
import NFTCard from "../components/Cards/NFTCard";
import DropdownBox from "../components/DropdownBox";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import exploreBanner from "../assets/img/exploreBanner.jpg";
import { useQuery } from "@apollo/client";
import { FilterNfts } from "../graphql/query";
import { Popover, Transition } from "@headlessui/react";
import { Form } from "antd";
import { RiArrowDropDownLine } from "react-icons/ri";
const category = [
  { name: "All Category", value: "" },
  { name: "Art", value: "art" },
  { name: "Sports", value: "sports" },
  { name: "Music", value: "music" },
  { name: "Utility", value: "utility" },
];
const status = [
  { name: "All Status", value: "" },
  { name: "Buy Now", value: "buy_now" },
  { name: "Live Auction", value: "live_auction" },
];
function ExplorePage() {
  const [filterInfo, setFilterInfo] = useState({
    priceMin: null,
    priceMax: null,
    network: "",
    category: "",
    isListed: false,
    // status: "",
  });

  const { data: filterNfts } = useQuery(FilterNfts, {
    variables: {
      // priceMin: null,
      // priceMax: null,
      // network: "",
      // category: "",
      // isListed: false,
      ...filterInfo,
    },
  });
  console.log(filterNfts);
  const handleCategory = (category) => {
    setFilterInfo({ ...filterInfo, category: category });
  };
  const handleStatus = (status) => {
    setFilterInfo({ ...filterInfo, status: status });
  };
  const handlePrice = (priceMin = null, priceMax = null) => {
    console.log(priceMin, priceMax);
    setFilterInfo({
      ...filterInfo,
      priceMin: parseFloat(priceMin),
      priceMax: parseFloat(priceMax),
    });
  };
  return (
    <>
      <div className='bg-blue-50'>
        <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0]  px-4">
            <div className="container">
                <div className="row lg:flex block container pt-5">
                    <div className="w-full">
                        <h1 className="text-white text-center text-4xl  font-bold">Explore</h1>

                    </div>
                </div>
            </div>
        </div>
      </div>
    
    <div className='bg-blue-50 py-12'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-4">
        <div className="container pt-16">

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className='lg:w-1/3 w-full'>
              <div className=" relative flex  items-stretch  ">
                <input
                  type="search"
                  className=" relative px-5 py-2 block w-full border border-black  border-r-0   text-base font-normal text-gray-700 bg-transparent rounded-lg rounded-r-none transition ease-in-out m-0  outline-none"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button
                  className="btn rounded-l-none px-5 py-2  inline-block bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out flex items-center "
                  type="button"
                >
                  <AiOutlineSearch size={20} />
                </button>
              </div>
            </div>
            <div className='lg:w-2/3 w-full flex lg:justify-end'>
              <div className="flex flex-wrap gap-5">
                <DropdownBox
                  data={category}
                  setFilterInfo={handleCategory}
                  filterInfo={filterInfo}
                />
                <DropdownBox
                  data={status}
                  setFilterInfo={handleCategory}
                  filterInfo={filterInfo}
                />
                <PriceFilter setFilterInfo={handlePrice} />
              </div>
            </div>
          </div>

          {filterNfts?.filterNfts?.length === 0 && (
            <div className="text-center text-black justify-center py-10 h-[30vh] items-center flex flex-col">
              <h3 className="mb-0 font-bold">Nothing found</h3>
              <p className="text-gray-500">
                We couldn't find anything with this criteria
              </p>
            </div>
          )}

          <div className="grid xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-4 grid-cols-2 xl:gap-[10px] gap-2">

            {filterNfts?.filterNfts.map((item, key) => (
              <Link
                key={key}
                to={`/asset/binance/${item.contractAddress}/${item.tokenId}`}
              >
                <NFTCard
                  name={item.name}
                  price={item.price}
                  chain={item.chainId}
                  image={item.imageUrl}
                  category={item.category}
                  owner={item.ownerUserId[0]}
                />
              </Link>
            ))}

          </div>
        </div>
      </div>
    </div>

    </>
  );
}
function PriceFilter({ setFilterInfo }) {
  return (
    <>
      <Popover className="relative">
        <Popover.Button className="flex text-black gap-2 relative cursor-default rounded-lg border border-black py-2  px-5  h-full  text-left shadow-md ">
          <span>Price</span>
          <span className="flexi items-center">
            <RiArrowDropDownLine size={25} />
          </span>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className=" bg-red left-1/2  absolute z-10 mt-3  -translate-x-1/2 transform px-4 sm:px-0 ">
            {({ open, close }) => (
              <>
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="">
                    <div className="p-5 w-[300px]">
                      <Form
                        onFinish={(values) => {
                          console.log(values);
                          setFilterInfo(values.priceMin, values.priceMax);
                          close();
                        }}
                        layout="vertical"
                        autoComplete="off"
                      >
                        <div className="flex gap-5 items-center">
                          <Form.Item name="priceMin">
                            <input
                              type="text"
                              placeholder="Min"
                              className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                            />
                          </Form.Item>
                          <span className="text-black">to</span>
                          <Form.Item name="priceMax">
                            <input
                              type="text"
                              placeholder="Max"
                              className="text-black bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                            />
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <button
                            htmlType="submit"
                            className=" mt-5 w-full  font-bold bg-primaryPink text-black  p-3 rounded-lg"
                          >
                            Apply
                          </button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <img src="/solutions.jpg" alt="" /> */}
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}

export default ExplorePage;
