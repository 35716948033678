import { Dialog, Transition } from "@headlessui/react";
import { DatePicker, Form, Switch } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import useERC721 from "../../hooks/useERC721";
import { PutMarketplaceContext } from "../../Provider/PutMarketplace";
import { UpdateListing } from "../../graphql/mutation";
import { useMutation } from "@apollo/client";
import { GetNft } from "../../graphql/query";
import { errorFilter, toDecimal } from "../../utils";
import { LoadingContext } from "../../Provider/LoadingProvider";
import {
  DEFAULT_NATIVE_TOKEN_ADDRESS,
  DEFAULT_WRAP_TOKEN,
  STEP,
} from "../../config/constant/url";
import { exit } from "process";
import {
  PUT_AUCTION_ALERT,
  PUT_SALE_ALERT,
  USER_DENIED,
} from "../../config/constant/alert";
function PutMarketplaceModal({ tokenId, nftId, address, ownerAddress }) {
  const [isAuction, setAuction] = useState(false);
  const { openMarketplace, setMarketplace } = useContext(PutMarketplaceContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { createListing, approve } = useERC721();
  const [updateListing] = useMutation(UpdateListing);
  return (
    <Transition appear show={openMarketplace} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setMarketplace(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center px-4 py-10 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white rounded-2xl bg-primaryWhite px-6 py-10 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h2"
                  className="text-3xl mb-5 font-bold leading-6 text-black"
                >
                  <span>Put on marketplace</span>
                  <div className="absolute top-0 w-[95%] right-0 flex justify-end p-5 ">
                    <span
                      className="cursor-pointer"
                      onClick={() => setMarketplace(false)}
                    >
                      <RxCross2 size={20} className="" />
                    </span>
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-base text-[#ffffff99]">
                    Select any one option where you want to put your NFT.
                  </p>
                </div>

                <div className="mt-4">
                  <div className="flex flex-col gap-4">
                    <Form
                      layout="vertical"
                      onFinish={async (values) => {
                        console.log(values.price);
                        showLoading();

                        let tx = await approve(tokenId)
                          .then()
                          .catch(async (err) => {
                            console.log(err);
                            await USER_DENIED(err.message.split(/([^(]+)/)[1]);
                            showLoading();
                            hideLoading();
                          });
                        await tx.wait();
                        tx = await createListing(
                          tokenId,
                          values.price,
                          isAuction ? values.bid_amount : "0",
                          isAuction
                            ? DEFAULT_WRAP_TOKEN
                            : DEFAULT_NATIVE_TOKEN_ADDRESS,
                          isAuction ? 1 : 0
                        )
                          .then()
                          .catch((err) => {
                            hideLoading();
                          });
                        let confirm = await tx.wait();
                        console.log(confirm);
                        updateListing({
                          variables: {
                            listingId: toDecimal(
                              isAuction
                                ? confirm.events[2].args.listingId._hex
                                : confirm.events[0].args.listingId._hex
                            ),
                            nftId: nftId,
                            price: parseFloat(values.price),
                            ownerAddress: ownerAddress,
                          },
                          refetchQueries: [
                            {
                              query: GetNft,
                              variables: {
                                contractAddress: address,
                                tokenId: parseInt(tokenId),
                              },
                            },
                          ],
                        })
                          .then((res) => {
                            console.log(res);
                            setMarketplace(false);
                            if (isAuction) {
                              PUT_AUCTION_ALERT();
                            } else {
                              PUT_SALE_ALERT();
                            }

                            hideLoading();
                          })
                          .catch((err) => {
                            console.log(err);
                            setMarketplace(false);
                            hideLoading();
                          });
                      }}
                    >
                      <div className="flex items-center justify-between my-3">
                        <div className="text-black">
                          <h3 className="mb-0 font-bold  text-base ">
                            Auction Enable
                          </h3>
                          <p className="mb-0 text-[12px]">
                            Set a period of time for which buyers can place bids
                          </p>
                        </div>
                        <div>
                          <Switch onChange={(checked) => setAuction(checked)} />
                        </div>
                      </div>
                      {/* {!isAuction && ( */}
                      <Form.Item
                        label="Price"
                        name="price"
                        className="text-black"
                        rules={[
                          {
                            required: true,
                            message: "Price is missing!",
                          },
                        ]}
                      >
                        <input
                          type="number"
                          step={STEP}
                          min={STEP}
                          placeholder="Enter the price"
                          className="text-[#ffffff] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                        />
                      </Form.Item>
                      {/* )} */}
                      {isAuction && (
                        <>
                          {" "}
                          <Form.Item
                            label="Minium Bid"
                            name="bid_amount"
                            className="text-black"
                            rules={[
                              {
                                required: true,
                                message: "Min bid price is missing!",
                              },
                            ]}
                          >
                            <input
                              type="number"
                              step={STEP}
                              min={STEP}
                              placeholder="Enter the price"
                              className="text-[#ffffff] bg-[#ffffff1a] rounded-md w-full px-3 py-2.5 outline-none"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Expire Date"
                            name="end_date"
                            className="text-black"
                            rules={[
                              {
                                required: true,
                                message: "Pick end date of auction!",
                              },
                            ]}
                          >
                            <DatePicker showTime />
                          </Form.Item>
                        </>
                      )}

                      <Form.Item>
                        <button className="w-full  mt-5 font-bold bg-primaryPink text-black  p-3 rounded-lg">
                          Confirm Listing
                        </button>
                        <button
                          onClick={() => {
                            setMarketplace(false);
                            setAuction(false);
                          }}
                          className=" text-center cursor-pointer  mt-3 w-full  font-bold bg-[#ffffff1a] text-black  p-3 rounded-lg"
                        >
                          Cancel
                        </button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PutMarketplaceModal;
