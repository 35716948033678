import React from "react";
import CreatorCard from "../components/Cards/CreatorCard";
import exploreBanner from "../assets/img/exploreBanner.jpg";
function CreatorPage() {
  return (
    <div>
      <section
        style={{
          background: `url(${exploreBanner})`,
          backgroundPosition: "bottom",
          backgroundSize: "initial",
        }}
      >
        <div className="container py-[130px] ">
          <h1 className="text-center font-bold text-black">Popular Creators</h1>
        </div>
      </section>
      <div className="h-[50px]"></div>
      <div className="px-5 md:px-[100px] py-[30px]">
        <div className="grid sm:grid-cols-3  md:grid-cols-3  lg:grid-cols-4 gap-5 ">
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
          <CreatorCard />
        </div>
      </div>
      <div className="h-[50px]"></div>
    </div>
  );
}

export default CreatorPage;
