import { gql } from "@apollo/client";

const CreateNFT = gql`
  mutation CreateNft(
    $tokenId: Int
    $name: String
    $ipfsUrl: String
    $imageUrl: String
    $chainId: Int
    $network: String
    $ownerAddress: String
    $creatorAddress: String
    $contractAddress: String
    $category: String
  ) {
    createNft(
      tokenId: $tokenId
      name: $name
      ipfsUrl: $ipfsUrl
      imageUrl: $imageUrl
      chainId: $chainId
      network: $network
      ownerAddress: $ownerAddress
      creatorAddress: $creatorAddress
      contractAddress: $contractAddress
      category: $category
    ) {
      _id
      name
      tokenId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      price
      contractAddress
      isListed
    }
  }
`;

const SignUp = gql`
  mutation SignUp(
    $walletAddress: String
    $lastname: String
    $firstname: String
    $username: String
  ) {
    signUp(
      walletAddress: $walletAddress
      lastname: $lastname
      firstname: $firstname
      username: $username
    ) {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
    }
  }
`;
const UpdateUser = gql`
  mutation UpdateUser(
    $userId: String
    $lastname: String
    $firstname: String
    $username: String
    $avatarUrl: String
    $bgImage: String
    $aboutDetails: String
    $twitterUrl: String
    $facebookUrl: String
    $websiteUrl: String
  ) {
    updateUser(
      userId: $userId
      lastname: $lastname
      firstname: $firstname
      username: $username
      avatar_url: $avatarUrl
      bg_image: $bgImage
      about_details: $aboutDetails
      twitterUrl: $twitterUrl
      facebookUrl: $facebookUrl
      websiteUrl: $websiteUrl
    ) {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
    }
  }
`;
const UpdateListing = gql`
  mutation UpdateListing(
    $listingId: Int
    $nftId: String
    $price: Float
    $ownerAddress: String
  ) {
    updateListing(
      listingId: $listingId
      nftId: $nftId
      price: $price
      ownerAddress: $ownerAddress
    ) {
      _id
      name
      tokenId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      price
      listingId
      contractAddress
      isAuction
      isListed
    }
  }
`;
const TransferUserNft = gql`
  mutation TransferUserNft(
    $buyerAddress: String
    $nftId: String
    $sellerId: String
  ) {
    transferUserNft(
      buyerAddress: $buyerAddress
      nftId: $nftId
      sellerId: $sellerId
    ) {
      _id
      displayName
      avatar_url
      about_details
      bg_image
      username
    }
  }
`;
const SetRating = gql`
  mutation SetRating(
    $rating: Int
    $comment: String
    $user: String
    $nftId: String
  ) {
    setRating(rating: $rating, comment: $comment, user: $user, nftId: $nftId) {
      _id
      rating
      comment
    }
  }
`;

const GetNftRating = gql`
  query GetNftRating($nftId: String) {
    getNftRating(nftId: $nftId) {
      _id
      rating
      comment
      user {
        _id
        username
        avatar_url
        isVerified
      }
    }
  }
`;

//JSON
const StaticPageUpdate = gql`
  mutation StaticPageUpdate(
    $type: String
    $title: String
    $body: String
  ) {
    StaticPageUpdate(type: $type, title: $title, body: $body) {
      _id
      type
      title
      body
    }
  }
`;
export {
  CreateNFT,
  SignUp,
  UpdateUser,
  UpdateListing,
  TransferUserNft,
  SetRating,
  GetNftRating,
  StaticPageUpdate
};
