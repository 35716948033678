import React from "react";

function ImgUpload() {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      width="24"
      height="24"
      xlmns="http://www.w3.org/2000/svg"
      className="sc-bdvvtL sc-iCfMLu KFXWT"
    >
      <path
        d="M6.75 4.99985C6.75 5.96635 5.9665 6.74985 5 6.74985C4.0335 6.74985 3.25 5.96635 3.25 4.99985C3.25 4.03335 4.0335 3.24985 5 3.24985C5.9665 3.24985 6.75 4.03335 6.75 4.99985Z"
        fill="#fff"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 0.249847C1.92893 0.249847 0.25 1.92878 0.25 3.99985V11.9998C0.25 14.0709 1.92893 15.7498 4 15.7498H8.47979C8.17149 15.063 8 14.3015 8 13.4998C8 10.4623 10.4624 7.99985 13.5 7.99985C14.3016 7.99985 15.0632 8.17134 15.75 8.47964V3.99985C15.75 1.92878 14.0711 0.249847 12 0.249847H4ZM1.75 3.99985C1.75 2.75721 2.75736 1.74985 4 1.74985H12C13.2426 1.74985 14.25 2.75721 14.25 3.99985V8.0311L11.9584 6.59884C11.391 6.24421 10.6573 6.30828 10.1599 6.7559L6.46256 10.0835C6.17937 10.3384 5.77595 10.4102 5.4222 10.2687L4.31158 9.82448C3.81 9.62385 3.23928 9.70842 2.81745 10.0459L1.75 10.8998V3.99985Z"
        fill="#fff"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 13.4998C9.5 11.2907 11.2909 9.49985 13.5 9.49985C15.7091 9.49985 17.5 11.2907 17.5 13.4998C17.5 15.709 15.7091 17.4998 13.5 17.4998C11.2909 17.4998 9.5 15.709 9.5 13.4998ZM13.5 10.9998C13.9142 10.9998 14.25 11.3356 14.25 11.7498V12.7498H15.25C15.6642 12.7498 16 13.0856 16 13.4998C16 13.9141 15.6642 14.2498 15.25 14.2498H14.25V15.2498C14.25 15.6641 13.9142 15.9998 13.5 15.9998C13.0858 15.9998 12.75 15.6641 12.75 15.2498V14.2498H11.75C11.3358 14.2498 11 13.9141 11 13.4998C11 13.0856 11.3358 12.7498 11.75 12.7498H12.75V11.7498C12.75 11.3356 13.0858 10.9998 13.5 10.9998Z"
        fill="#fff"
      ></path>
    </svg>
  );
}

export default ImgUpload;
