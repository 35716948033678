import React, { createContext, useState } from "react";
import { WagmiConfig, createClient, configureChains, mainnet } from "wagmi";
import { avalanche, bscTestnet } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, bscTestnet],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});
export const WalletContext = createContext();

function WalletProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <WalletContext.Provider value={[loading]}>
      <WagmiConfig client={client}>{children}</WagmiConfig>
    </WalletContext.Provider>
  );
}

export default WalletProvider;
