import { Form, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ImgUpload from "../assets/SVG/ImgUpload";
import staticImg from "../assets/img/2.jpg";
import { useMutation, useQuery } from "@apollo/client";
import { UserInfo } from "../graphql/query";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import { UpdateUser } from "../graphql/mutation";
import useStorage from "../hooks/useStorage";
import { replaceIPFS } from "../utils";
import { LoadingContext } from "../Provider/LoadingProvider";
function EditProfile() {
  const { address } = useContext(WalletConnectContext);
  const { uploadOnIpfs } = useStorage();
  const [bannerImg, setBanner] = useState(null);
  const [profileImg, setProfile] = useState(null);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { data: userInfo, loading } = useQuery(UserInfo, {
    skip: !address,
    variables: {
      walletAddress: address,
    },
  });
  console.log(userInfo);
  const [updateUser] = useMutation(UpdateUser);
  return (
    <div>
      <div className="container py-[50px]">
        <div>
          {!loading && (
            <div className="lg:container container-fluid bg-white py-[50px]">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  showLoading();

                  let username = document.getElementById("username").value;
                  let aboutDetail =
                    document.getElementById("about_details").value;
                  let firstName = document.getElementById("firstName").value;
                  let lastName = document.getElementById("lastName").value;
                  let avatarUrl = profileImg
                    ? replaceIPFS(await uploadOnIpfs(profileImg))
                    : userInfo?.user?.avatar_url;
                  let bg_image = bannerImg
                    ? replaceIPFS(await uploadOnIpfs(bannerImg))
                    : userInfo?.user?.bg_image;

                  await updateUser({
                    variables: {
                      userId: userInfo?.user?._id,
                      firstname: firstName,
                      lastname: lastName,
                      username: username,
                      avatarUrl: avatarUrl,
                      bgImage: bg_image,
                      aboutDetails: aboutDetail,
                      twitterUrl: null,
                      facebookUrl: null,
                      websiteUrl: null,
                    },
                    refetchQueries: [
                      {
                        query: UserInfo,
                        variables: {
                          walletAddress: address,
                        },
                      },
                    ],
                  })
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((err) => console.error(err));
                  hideLoading();
                }}
                layout="vertical"
              >
                <div className="group profile-banner relative overflow-hidden text-transparent lg:rounded-xl shadow dark:shadow-gray-700">
                  <input
                    id="pro-banner"
                    name="profile-banner"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setBanner(e.target.files[0]);
                    }}
                    type="file"
                    style={{ display: "none" }}
                  />

                  <div className="relative shrink-0">
                    <img
                      src={
                        bannerImg
                          ? URL.createObjectURL(bannerImg)
                          : userInfo?.user?.bg_image
                      }
                      className="h-80 w-full object-cover rounded-md"
                      id="profile-banner"
                      alt
                    />
                    <div className="absolute inset-0 bg-slate/10 group-hover:bg-slate-900/40 transition duration-500">
                      <div className="hidden  group-hover:flex  justify-center items-center h-full">
                        <ImgUpload />
                      </div>
                    </div>
                    <label
                      className="absolute inset-0 cursor-pointer "
                      htmlFor="pro-banner"
                    />
                  </div>
                </div>
                <div className="md:flex justify-center">
                  <div className="md:w-full">
                    <div className="relative -mt-[60px] text-center">
                      <div className="group profile-pic w-[112px] mx-auto ">
                        <input
                          id="pro-img"
                          name="profile-image"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files[0]);
                            setProfile(e.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        <div>
                          <div className="relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                            <img
                              src={""}
                              className="rounded-full"
                              id="profile-image"
                              alt
                            />
                            <img
                              className="w-full h-full object-cover"
                              src={
                                profileImg
                                  ? URL.createObjectURL(profileImg)
                                  : userInfo?.user?.avatar_url
                              }
                            ></img>
                            <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500 ">
                              <div className="hidden  group-hover:flex  justify-center items-center h-full">
                                <ImgUpload />
                              </div>
                            </div>

                            <label
                              className="absolute inset-0 cursor-pointer"
                              htmlFor="pro-img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //Input  */}
                <div className="">
                  <div className=" mt-2">
                    <label className=" text-[14px] text-black  mb-0 ">
                      Username
                    </label>
                    <div className="flex items-center mt-[8px] --text-[#ffffff] --bg-[#ffffff1a] rounded-md ">
                      <div className="pl-3 bg-gray-100 h-[41px] flex justify-center items-center">
                        <span>@</span>
                      </div>
                      <div className="w-full ">
                        <input
                          type="text"
                          id="username"
                          defaultValue={userInfo?.user?.username}
                          placeholder="Enter your username"
                          className="bg-gray-100 --text-[#ffffff] text-[14px]  w-full pl-1 pr-3 py-2.5 outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" mt-2">
                    <label className=" text-[14px] text-black  mb-0 ">
                      First Name
                    </label>
                    <input
                      type="text"
                      placeholder="Your first name"
                      id="firstName"
                      defaultValue={userInfo?.user?.firstname}
                      className="mt-[8px] --text-[#ffffff] bg-gray-100 text-[14px] rounded-md w-full px-3 py-2.5 outline-none "
                    />
                  </div>
                  <div className=" mt-2">
                    <label className=" text-[14px] text-black  mb-0 ">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Your last name"
                      id="lastName"
                      defaultValue={userInfo?.user?.lastname}
                      className="mt-[8px] --text-[#ffffff] bg-gray-100 text-[14px] rounded-md w-full px-3 py-2.5 outline-none "
                    />
                  </div>
                  <div className=" mt-2">
                    <label className=" text-[14px] text-black  mb-0 ">
                      About
                    </label>
                    <textarea
                      type="text"
                      placeholder="Tell me about your self"
                      id="about_details"
                      defaultValue={userInfo?.user?.about_details}
                      className="mt-[8px] --text-[#ffffff] bg-gray-100 text-[14px] rounded-md w-full px-3 py-2.5 outline-none h-[100px]"
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="mt-5 font-bold bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white rounded-lg text-sm px-10 py-3 rounded-lg"
                  >
                    Save Profile
                  </button>
                </div>{" "}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
