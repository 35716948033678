import React, { createContext, useState } from "react";

export const LoadingContext = createContext();
function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const hideLoading = () => {
    setLoading(false);
  };
  const showLoading = () => {
    setLoading(true);
  };
  return (
    <LoadingContext.Provider
      value={{
        loading,
        hideLoading: hideLoading,
        showLoading: showLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;
