import { gql } from "@apollo/client";

const FilterNfts = gql`
  query FilterNfts(
    $priceMin: Float
    $priceMax: Float
    $network: String
    $category: String
    $isListed: Boolean
  ) {
    filterNfts(
      price_min: $priceMin
      price_max: $priceMax
      network: $network
      category: $category
      isListed: $isListed
    ) {
      _id
      name
      tokenId
      listingId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      isAuction
      price
      contractAddress
      isListed
      ownerUserId {
        avatar_url
        username
        isVerified
      }
    }
  }
`;
const SignIn = gql`
  query SignIn($walletAddress: String) {
    signIn(walletAddress: $walletAddress) {
      _id
      address
      isPrimary
      user {
        _id
      }
    }
  }
`;
const GetNft = gql`
  query GetNftDetails($contractAddress: String, $tokenId: Int) {
    getNftDetails(contractAddress: $contractAddress, tokenId: $tokenId) {
      nft {
        _id
        name
        tokenId
        ipfsUrl
        imageUrl
        category
        chainId
        network
        ownerAddress
        creatorAddress
        isMarketPlace
        isApproved
        price
        listingId
        contractAddress
        isAuction
        isListed
        reviews {
          _id
          rating
          comment
        }
      }
      ratingSum
      user {
        _id
        username
        avatar_url
        isVerified
      }
      creator {
        _id
        username
        avatar_url
        isVerified
      }
    }
  }
`;
const TopNFT = gql`
  query GetNewNfts {
    getNewNfts {
      _id
      name
      tokenId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      price
      contractAddress
      isListed
    }
  }
`;

const SearchNFT = gql`
  query SearchNfts($key: String) {
    searchNfts(key: $key) {
      _id
      name
      tokenId
      listingId
      ipfsUrl
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      isMarketPlace
      isApproved
      price
      contractAddress
      isListed
    }
  }
`;
const UserInfo = gql`
  query User($walletAddress: String) {
    user(walletAddress: $walletAddress) {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
      following_list {
        _id
      }
      follower_list {
        _id
      }
      nfts {
        _id
        name
        tokenId
        ipfsUrl
        listingId
        imageUrl
        category
        chainId
        network
        ownerAddress
        creatorAddress
        isMarketPlace
        isApproved
        price
        contractAddress
        isAuction
        isListed
      }
    }
  }
`;
const GetTopCreator = gql`
  query GetTopCreator {
    getTopCreator {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
      follower_list {
        _id
      }
      wallets {
        _id
        address
      }
    }
  }
`;
const GetRating = gql`
  query GetRating($userId: String, $nftId: String) {
    getRating(userId: $userId, nftId: $nftId) {
      _id
      rating
      comment
    }
  }
`;

const Users = gql`
  query Users {
    users {
      _id
      displayName
      username
      avatar_url
      about_details
      bg_image
      twitterUrl
      facebookUrl
      firstname
      lastname
      websiteUrl
      isVerified
    }
  }
`;
const GetAllNfts = gql`
  query Nfts {
    nfts {
      _id
      name
      tokenId
      ipfsUrl
      listingId
      imageUrl
      category
      chainId
      network
      ownerAddress
      creatorAddress
      contractAddress
      isMarketPlace
      isApproved
      isAuction
      isListed
      price
    }
  }
`;

const GetStaticPage = gql`
  query getStaticPage($type: String) {
    getStaticPage(type: $type) {
      _id
      type
      title
      body
    }
  }
`;

export {
  Users,
  FilterNfts,
  SignIn,
  GetNft,
  TopNFT,
  SearchNFT,
  UserInfo,
  GetTopCreator,
  GetRating,
  GetAllNfts,
  GetStaticPage,
};
