import React, { useContext } from "react";
import { MdVerified } from "react-icons/md";
import { Tab } from "@headlessui/react";
import NFTCard from "../components/Cards/NFTCard";
import { Link, useLocation, useParams } from "react-router-dom";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { useQuery } from "@apollo/client";
import { UserInfo } from "../graphql/query";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import { truncateAddress } from "../utils";
import ProfileNfts from "../components/Section/ProfileNfts";

import { Globe, Upload, GripHorizontal, Twitter, Instagram, Facebook } from 'lucide-react';

function Profile() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const { address } = useContext(WalletConnectContext);
  const { data: userInfo } = useQuery(UserInfo, {
    skip: !address,
    variables: {
      walletAddress: address,
    },
  });

  return (
    <>
      <div className="bg-blue-50">
        <div className="absolute top-0 left-0 w-full lg:h-16 h-14 bg-cover bg-bottom  lg:bg-[url('/assets/images/ProfileBh.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0] px-4"></div>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-4">
        <div className=" pt-2">

          <div className='relative'>
            <div className="flex justify-end space-x-2 pb-2 absolute xl:top-[180px] lg:top-[136px] md:top-[100px] top-[20px] lg:right-[36px] right-[20px]">
                {/* <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white ">Edit Cover Photo</button> */}
                <Link to="/edit-profile"><button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white ">Edit Profile</button></Link>
            </div>
          </div>
          {/* <img className="h-auto rounded-xl" src="/assets/images/profilebackground.svg" alt="" width="auto" /> */}

          <img className="h-auto max-h-[275px] w-full rounded-xl" 
            src={
              userInfo
                ? userInfo?.user?.bg_image
                : "/assets/images/profilebackground.svg"
            }
          alt="" width="auto" />

          <div className="container row lg:flex block gap-4">
            <div className="@responsive xl:w-1/4 lg:w-2/5 w-full">
              <div className="bg-white p-4 shadow-2xl rounded-md lg:mt-[-60px] mt-0">
                  <div>
                      {/* <img className="h-auto rounded-full mx-auto" src="/assets/images/profileimg.svg" alt="" width="auto" /> */}
                      <img className="h-auto rounded-full mx-auto" 
                        src={
                          userInfo
                            ? userInfo?.user?.avatar_url
                            : "https://www.pngitem.com/pimgs/m/504-5040528_empty-profile-picture-png-transparent-png.png"
                        }
                      alt="" width="auto" />

                      
                      <div className="text-center pt-2">
                          <h3 className="font-medium text-lg gap-2 flex items-center justify-center">
                            <span>{userInfo?.user?.username}</span>
                            {userInfo?.user?.isVerified && (
                              <span className="flex items-center text-primaryPink cursor-pointer">
                                <Tooltip
                                  title="Verified"
                                  position="bottom"
                                  trigger="mouseenter"
                                >
                                  <MdVerified size={20} />
                                </Tooltip>
                              </span>
                            )}
                          </h3>
                          <p className="mb-1 text-sm text-gray-500">
                            Created by{" "}
                            <span className="text-primaryPink font-semibold cursor-pointer">
                              <Tooltip
                                title={address}
                                position="bottom"
                                trigger="mouseenter"
                              >
                                {truncateAddress(address)}
                              </Tooltip>
                            </span>
                          </p>

                          <p className="py-2 text-gray-400 text-sm">{userInfo?.user?.about_details}</p>
                          {/* <div className="flex items-center justify-center mb-6">
                              <span className="mr-2">
                                  <Globe size={16} color="#878787" strokeWidth={0.75} />
                              </span>
                              <p className="text-sm">http://swadesi.io/profile</p>
                          </div> */}
                      </div>
                      <div className="flex gap-4 items-center justify-center">
                        <div className="text-center">
                          <h3 className="mb-0 font-bold text-black">
                            {userInfo?.user?.nfts?.length}
                          </h3>
                          <h6 className="mb-0 text-sm font-semibold text-gray-400">
                            NFT
                          </h6>
                        </div>{" "}
                        <div className="text-center">
                          <h3 className="mb-0 font-bold text-black">
                            {userInfo?.user?.follower_list?.length}
                          </h3>
                          <h6 className="mb-0 text-sm font-semibold text-gray-400">
                            Followers
                          </h6>
                        </div>{" "}
                        <div className="text-center">
                          <h3 className="mb-0 font-bold text-black">
                            {" "}
                            {userInfo?.user?.following_list?.length}
                          </h3>
                          <h6 className="mb-0 text-sm font-semibold text-gray-400">
                            Following
                          </h6>
                        </div>
                      </div>
                      {/* <div className="flex justify-center space-x-2 pb-2">
                          <button className="bg-green-500 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white font-bold py-2 px-4 rounded-full">Follow </button>
                          <button className="border bg-white text-black py-2 px-2 rounded-full"><Upload size={20} color="#878787" strokeWidth={1.25} /></button>
                          <button className="border bg-white text-black py-2 px-2 rounded-full"><GripHorizontal size={20} color="#878787" strokeWidth={1} /></button>
                      </div>

                      <div className="flex justify-center space-x-2 pb-2 py-4">
                          <button className="px-2"><Twitter size={20} color="#575757" strokeWidth={1} /> </button>
                          <button className="px-2"><Instagram size={20} color="#575757" strokeWidth={1} /></button>
                          <button className="px-2"><Facebook size={20} color="#575757" strokeWidth={1} /></button>
                      </div>
                      <button className="block w-full py-4 border-t border-gray-50 text-xs text-gray-500 hover:text-gray-800 uppercase">Member since Mar 15, 2023</button> */}
                  </div>
              </div>
            </div>
            
            <div className="@responsive xl:w-3/4 lg:w-3/5 w-full">
              <div className="mt-4">
                <ProfileNfts nfts={userInfo} />
              </div>
            </div>
          </div>

        </div>
      </div>


    </>
  );
}

export default Profile;
