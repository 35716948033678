import { ChainsInfo } from "../config/config-chains";
import { DEFAULT_CHAIN_ID, IPFS_URL } from "../config/constant/url";
import { BigNumber } from "@ethersproject/bignumber";
import { ethers } from "ethers";
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
export const toDecimal = (hexa) => {
  const val = parseInt(hexa);
  return val;
};

export const formatDecimalToUint = (num, formatDecimal) => {
  if (num) {
    return ethers.utils.formatUnits(num, formatDecimal);
  }
};
export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const nowTime = () => {
  return parseInt(new Date().getTime() / 1000);
};


export const replaceIPFS = (link) => {
  if (!link) return link;
  if (link.startsWith("ipfs://")) {
    return link.replace("ipfs://", IPFS_URL);
  }
};

export function calculateGasMargin(value, margin = 1000) {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(margin)))
    .div(BigNumber.from(10000));
}

export const getBlockExplore = (transaction, address, tokenId = 0) => {
  switch (transaction) {
    case "tokenId":
      return (
        ChainsInfo[DEFAULT_CHAIN_ID].BLOCK_EXPLORER_URL +
        "/token/" +
        address +
        "?a=" +
        tokenId
      );

    case "contractAddress":
      return (
        ChainsInfo[DEFAULT_CHAIN_ID].BLOCK_EXPLORER_URL + "/address/" + address
      );

    default:
      return ChainsInfo[DEFAULT_CHAIN_ID].BLOCK_EXPLORER_URL;
  }
};
