import React from "react";
import exploreBanner from "../../assets/img/exploreBanner.jpg";
import { useQuery } from "@apollo/client";
import { GetStaticPage } from "../../graphql/query";

function Terms() {

  const { data, loading, error } = useQuery(GetStaticPage, {
    variables: {
      type: 'terms_conditions',
    },
  });

  return (
    <>

      <div className='bg-blue-50'>
        <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0]  px-4">
          <div className="container">
            <div className="row lg:flex block container pt-4">
              <div className="w-full">
                {/* <h1 className="text-white text-center text-4xl  font-bold">Terms & Conditions</h1> */}
                <h1 className="text-white text-center text-4xl  font-bold">{data?.getStaticPage?.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">
        <div className="container pt-16">

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className="w-full text-center">
              <h1 className="text-2xl font-bold">Introduction</h1>
              {/* <p className="py-4">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p> */}
              <p className="py-4">{data?.getStaticPage?.body?.introduction}</p>
            </div>
          </div>

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className="w-full text-center">
              <h1 className="text-2xl font-bold">User Agreements</h1>
              {/* <p className="py-4">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script. The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.</p> */}
              <p className="py-4">{data?.getStaticPage?.body?.userAgreements}</p>
            </div>
          </div>

          <div className="h-32"></div>

        </div>
      </div>

    </>
  );
}

export default Terms;
