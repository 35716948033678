import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SiEthereum, SiIpfs } from "react-icons/si";
import { BsArrowUpRight } from "react-icons/bs";
import useERC721 from "../hooks/useERC721";
import useStorage from "../hooks/useStorage";

import { Star } from "lucide-react";

import {
  getBlockExplore,
  nowTime,
  replaceIPFS,
  toDecimal,
  truncateAddress,
} from "../utils";
import { Rate, Skeleton, Avatar } from "antd";
import { ChainsInfo } from "../config/config-chains";
import { GetNft, SignIn } from "../graphql/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Tooltip } from "react-tippy";
import { MdVerified } from "react-icons/md";
import { WalletConnectContext } from "../Provider/WalletConnectProvider";
import PutMarketplaceModal from "../components/Modal/PutMarketplaceModal";
import { PutMarketplaceContext } from "../Provider/PutMarketplace";
import {
  GetNftRating,
  TransferUserNft,
  UpdateListing,
} from "../graphql/mutation";
import CounterComponent from "../components/Counter";
import RatingModal from "../components/Modal/RatingModal";
import { LoadingContext } from "../Provider/LoadingProvider";
import {
  BUY_NFT_ALERT,
  REMOVE_SALE_ALERT,
  USER_DENIED,
} from "../config/constant/alert";
import { BigNumber } from "ethers";

function isOdd(num) { return num % 2;}

function ItemPage() {
  const {
    tokenURI,
    buy,
    listings,
    ownerOf,
    cancelDirectListing,
    closeAuction,
  } = useERC721();
  const { network, address, tokenId } = useParams();
  const {
    address: walletAddress,
    isConnected,
    handleModal,
  } = useContext(WalletConnectContext);
  const { downloadJSONOnIpfs } = useStorage();
  const [metadata, setMetadata] = useState({});
  let [isRatingModalOpen, setRatingModalOpen] = useState(false);
  const [isOwner, setOwner] = useState(false);
  const [listing, setListing] = useState({});
  const [UsdPrice, setUsdPrice] = useState(0);
  const [ipfsLink, setIpfs] = useState(null);
  const [updateListing] = useMutation(UpdateListing);
  const [transferUserNft] = useMutation(TransferUserNft);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { openMarketplace, setMarketplace } = useContext(PutMarketplaceContext);
  const [signIn, { data: signInfo }] = useLazyQuery(SignIn);

  const { data: getNft, loading } = useQuery(GetNft, {
    variables: {
      contractAddress: address,
      tokenId: parseInt(tokenId),
    },
  });
  const { data: getNftRating } = useQuery(GetNftRating, {
    skip: !getNft?.getNftDetails?.nft?._id,
    variables: {
      nftId: getNft?.getNftDetails?.nft?._id,
    },
  });

  //Get Metadata of nft
  useEffect(() => {
    tokenURI(tokenId).then(async (res) => {
      setIpfs(res);
      setMetadata(await downloadJSONOnIpfs(res));
    });
  }, [loading]);
  useEffect(() => {
    if (getNft?.getNftDetails?.nft?.listingId) {
      listings(getNft?.getNftDetails?.nft?.listingId).then(async (res) => {
        setListing(res);
        //console.log(res);
      });
    } else {
      setListing({});
    }
  }, [loading, getNft]);

  useEffect(() => {
    ownerOf(tokenId).then(async (res) => {
      setOwner(res === walletAddress);
    });
    signIn({
      variables: {
        walletAddress: walletAddress,
      },
    });
  }, [walletAddress, loading]);

  //Get USD price of NFT
  useEffect(() => {
    if (getNft?.getNftDetails?.nft?.chainId) {
      fetch(
        "https://cex.io/api/last_price/" +
          ChainsInfo[getNft?.getNftDetails?.nft?.chainId]?.CURRENCY_SYMBOL +
          "/USD"
      )
        .then((res) => res.json())
        .then((data) => {
          setUsdPrice(parseFloat(data.lprice));
        });
    }
  }, [loading]);

  console.log("metadata", metadata);

  return (
    <>
      <div className='bg-blue-50'>
        <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0]  px-4">
            <div className="container">
                <div className="row lg:flex block container pt-4">
                    <div className="w-full">
                      <h1 className="text-white text-center text-4xl  font-bold">NFT</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>


      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-4">
        <div className="container pt-16">
            <div className="row lg:flex block lg:py-4 py-0 lg:p-4 gap-6">
                <div className='lg:w-2/5 w-full'>
                    {/* <img class="h-full" src="/assets/images/Rectangle 196.svg" alt="" width="full" /> */}
                    {metadata?.image ? (
                      <img
                        className="h-full object-cover rounded-2xl"
                        src={metadata?.image}
                      />
                    ) : (
                      <Skeleton.Image active={true} loading={false} size={"100%"} />
                    )}
                </div>
                <div className='lg:w-3/5 w-full mt-8'>
                     {/* //NFT Infor */}
                    <p className="uppercase text-blue-600  text-lg">Multicolor abstract Painting</p>
                    {metadata?.name ? (
                      <>
                        {" "}
                        <p className="py-1 text-black font-bold text-2xl">{metadata?.name}</p>
                        <p className="text-md ">{metadata?.description}</p>
                      </>
                    ) : (
                      <Skeleton active />
                    )}

                     {/* //Creator  */}
                    
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 grid-cols-1 xl:gap-[10px] gap-2 mt-2">

                      <div>
                        <Link
                          to={
                            "/creator-profile/" +
                            getNft?.getNftDetails?.nft?.creatorAddress
                          }
                        >
                          <div class="flex items-center gap-4 bg-white rounded-lg py-2 p-2">
                            <img class="w-6 h-6 rounded-full" src={getNft?.getNftDetails?.creator?.avatar_url} alt="" />
                            <div class="font-medium dark:text-white">
                              <p className='text-black font-light text-sm'> Creator <span className="font-bold" text-black>
                                @{getNft?.getNftDetails?.creator?.username}{" "}
                                {getNft?.getNftDetails?.creator?.isVerified && (
                                  <span className="flex items-center text-primaryPink cursor-pointer">
                                    <Tooltip
                                      title="Verified"
                                      position="bottom"
                                      trigger="mouseenter"
                                    >
                                      <MdVerified size={15} />
                                    </Tooltip>
                                  </span>
                                )}
                              </span></p>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div>
                        <Link
                          to={
                            "/creator-profile/" +
                            getNft?.getNftDetails?.nft?.ownerAddress
                          }
                        >
                          <div class="flex items-center gap-4 bg-white rounded-lg py-2 p-2">
                            <img class="w-6 h-6 rounded-full" src={getNft?.getNftDetails?.user?.avatar_url} alt="BAYC" />
                            <div class="font-medium dark:text-white">
                              <p className='text-black font-light text-sm'> Owner <span className="font-bold" text-black>
                                @{getNft?.getNftDetails?.user?.username}{" "}
                                {getNft?.getNftDetails?.user?.isVerified && (
                                  <span className="flex items-center text-primaryPink cursor-pointer">
                                    <Tooltip
                                      title="Verified"
                                      position="bottom"
                                      trigger="mouseenter"
                                    >
                                      <MdVerified size={15} />
                                    </Tooltip>
                                  </span>
                                )}
                              </span></p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* //Rating */}

                    <div className="flex items-center mb-2 mt-2">
                      <span
                        className=" px-3 p-1 rounded-full flex flex-col"
                        onClick={() => setRatingModalOpen(true)}
                      >
                        <Rate
                          disabled="true"
                          value={getNft?.getNftDetails?.ratingSum}
                        />
                        <span className="text-black text-[15px]">
                          {getNft?.getNftDetails?.ratingSum} out of 5{" "}
                        </span>
                      </span>
                      <RatingModal
                        setIsOpen={setRatingModalOpen}
                        isOpen={isRatingModalOpen}
                        userId={signInfo?.signIn?.user?._id}
                        nftId={getNft?.getNftDetails?.nft?._id}
                        contractAddress={address}
                        tokenId={tokenId}
                      />
                      <span className="ml-5  text-black text-[12px] text-center">
                        ({getNft?.getNftDetails?.nft?.reviews.length}) reviews
                      </span>
                    </div>

                    {/* //NFT Price */}

                    <div className="pt-4">
                        <p className="text-blue-500">Current Price</p>
                        <p className="py-1 text-black font-light text-2xl">{getNft?.getNftDetails?.nft?.price} {ChainsInfo[97].CURRENCY_SYMBOL}</p>
                        <p className="text-blue-600">${UsdPrice * parseFloat(getNft?.getNftDetails?.nft?.price)}</p>

                        <div className="flex justify-end">
                          {(listing?.listingType &&
                            toDecimal(listing?.startTime) >= nowTime()) ||
                            (toDecimal(listing?.endTime) <= nowTime() && (
                              <p className="text-primaryPink font-bold bg-white py-0 px-4 rounded-full text-[12px] bg-[#ff343f33]">
                                Sale End
                              </p>
                            ))}
                        </div>

                        {listing?.listingType &&
                          toDecimal(listing?.startTime) <= nowTime() &&
                          toDecimal(listing?.endTime) >= nowTime() && (
                            <div className="mb-5 flex justify-end ">
                              <CounterComponent
                                endDate={toDecimal(listing?.endTime?._hex)}
                              />
                            </div>
                          )}
                        {!isConnected && (
                          <button
                            className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                            onClick={() => handleModal(true)}
                          >
                            Connect Wallet
                          </button>
                        )}

                        {isConnected &&
                          getNft?.getNftDetails?.nft?.ownerAddress === walletAddress &&
                          getNft?.getNftDetails?.nft?.listingId === null && (
                            <button
                              className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                              onClick={() => setMarketplace(true)}
                            >
                              Put on marketplace
                            </button>
                          )}

                        {isConnected &&
                          getNft?.getNftDetails?.nft?.ownerAddress === walletAddress &&
                          toDecimal(listing?.quantity?._hex) >= 0 &&
                          listing?.listingType === 0 && (
                            <button
                              className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                              onClick={async () => {
                                showLoading();
                                let tx = await cancelDirectListing(
                                  getNft?.getNftDetails?.nft?.listingId
                                )
                                  .then()
                                  .catch(async (err) => {
                                    await USER_DENIED(err.message.split(/([^(]+)/)[1]);
                                    hideLoading();
                                  });
                                await tx.wait();

                                updateListing({
                                  variables: {
                                    listingId: null,
                                    nftId: getNft?.getNftDetails?.nft?._id,
                                    price: 0,
                                    ownerAddress:
                                      getNft?.getNftDetails?.nft?.ownerAddress,
                                  },
                                  refetchQueries: [
                                    {
                                      query: GetNft,
                                      variables: {
                                        contractAddress: address,
                                        tokenId: parseInt(tokenId),
                                      },
                                    },
                                  ],
                                })
                                  .then((res) => {
                                    hideLoading();
                                    REMOVE_SALE_ALERT();
                                  })
                                  .catch((err) => {
                                    hideLoading();
                                  });
                              }}
                            >
                              Remove From Marketplace
                            </button>
                          )}

                        {isConnected &&
                          getNft?.getNftDetails?.nft?.ownerAddress === walletAddress &&
                          toDecimal(listing?.quantity?._hex) >= 0 &&
                          listing?.listingType === 1 && (
                            <button
                              className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                              onClick={async () => {
                                showLoading();
                                let tx = await closeAuction(
                                  getNft?.getNftDetails?.nft?.listingId
                                )
                                  .then()
                                  .catch((error) => {
                                    hideLoading();
                                  });

                                await tx.wait();

                                updateListing({
                                  variables: {
                                    listingId: null,
                                    nftId: getNft?.getNftDetails?.nft?._id,
                                    price: 0,
                                    ownerAddress:
                                      getNft?.getNftDetails?.nft?.ownerAddress,
                                  },
                                  refetchQueries: [
                                    {
                                      query: GetNft,
                                      variables: {
                                        contractAddress: address,
                                        tokenId: parseInt(tokenId),
                                      },
                                    },
                                  ],
                                })
                                  .then((res) => {
                                    hideLoading();
                                  })
                                  .catch((err) => {
                                    hideLoading();
                                  });
                              }}
                            >
                              Remove From Auction
                            </button>
                          )}
                        
                        <PutMarketplaceModal
                          tokenId={tokenId}
                          nftId={getNft?.getNftDetails?.nft?._id}
                          ownerAddress={getNft?.getNftDetails?.nft?.ownerAddress}
                          address={address}
                        />


                        <div>
                          {isConnected &&
                            getNft?.getNftDetails?.nft?.ownerAddress !==
                              walletAddress &&
                            toDecimal(listing?.quantity?._hex) > 0 &&
                            toDecimal(listing?.startTime) <= nowTime() &&
                            toDecimal(listing?.endTime) >= nowTime() &&
                            listing?.listingType && (
                              <button
                                className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                                onClick={async () => {
                                  let tx = await buy(
                                    getNft?.getNftDetails?.nft?.listingId,
                                    toDecimal(listing?.buyoutPricePerToken?._hex)
                                  )
                                    .then((res) => console.log(res))
                                    .catch((error) => {
                                      hideLoading();
                                    });

                                  await tx.wait();

                                  updateListing({
                                    variables: {
                                      listingId: null,
                                      nftId: getNft?.getNftDetails?.nft?._id,
                                      price: 0,
                                      ownerAddress:
                                        getNft?.getNftDetails?.nft?.ownerAddress,
                                    },
                                    refetchQueries: [
                                      {
                                        query: GetNft,
                                        variables: {
                                          contractAddress: address,
                                          tokenId: parseInt(tokenId),
                                        },
                                      },
                                    ],
                                  })
                                    .then((res) => {
                                      hideLoading();
                                    })
                                    .catch((err) => {
                                      hideLoading();
                                    });
                                }}
                              >
                                Place Bid
                              </button>
                            )}
                          {isConnected &&
                            getNft?.getNftDetails?.nft?.ownerAddress !==
                              walletAddress &&
                            toDecimal(listing?.quantity?._hex) > 0 &&
                            listing?.listingType === 0 && (
                              <button
                                className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200"
                                onClick={async () => {
                                  console.log(
                                    getNft?.getNftDetails?.nft?.listingId,
                                    toDecimal(listing?.buyoutPricePerToken?._hex)
                                  );
                                  let tx = await buy(
                                    getNft?.getNftDetails?.nft?.listingId,

                                    listing?.buyoutPricePerToken?._hex
                                  )
                                    .then()
                                    .catch((error) => {
                                      console.log(error);
                                      hideLoading();
                                    });

                                  await tx.wait();

                                  updateListing({
                                    variables: {
                                      listingId: null,
                                      nftId: getNft?.getNftDetails?.nft?._id,
                                      price: 0,
                                      ownerAddress:
                                        getNft?.getNftDetails?.nft?.ownerAddress,
                                    },
                                    refetchQueries: [
                                      {
                                        query: GetNft,
                                        variables: {
                                          contractAddress: address,
                                          tokenId: parseInt(tokenId),
                                        },
                                      },
                                    ],
                                  })
                                    .then((res) => {})
                                    .catch((err) => {});

                                  transferUserNft({
                                    variables: {
                                      buyerAddress: walletAddress,
                                      nftId: getNft?.getNftDetails?.nft?._id,
                                      sellerId: getNft?.getNftDetails?.user?._id,
                                    },
                                    refetchQueries: [
                                      {
                                        query: GetNft,
                                        variables: {
                                          contractAddress: address,
                                          tokenId: parseInt(tokenId),
                                        },
                                      },
                                    ],
                                  })
                                    .then((res) => {
                                      hideLoading();
                                      BUY_NFT_ALERT();
                                    })
                                    .catch((err) => {
                                      hideLoading();
                                    });
                                }}
                              >
                                Buy Now
                              </button>
                            )}

                          {isConnected &&
                            getNft?.getNftDetails?.nft?.ownerAddress !==
                              walletAddress &&
                            (toDecimal(listing?.quantity?._hex) === 0 ||
                              getNft?.getNftDetails?.nft?.listingId === null) && (
                              <button className="mt-2 shadow-md py-2 px-4 bg-gradient-to-b from-[#FF1CF7] to-[#00F0FF] text-white text-sm rounded-full border-2 border-gray-200">
                                Not Listed
                              </button>
                            )}
                        </div>
                        
                        {/* <p className="mt-2 text-sm text-gray-700">Auction ends in</p> */}
                    </div>
                </div>
            </div>

            <InfoCards
              metadata={metadata}
              address={address}
              tokenId={tokenId}
              ownerAddress={address}
              ipfsLink={ipfsLink}
            />

            <div className="bg-white rounded-lg p-6 my-5">
                <p className="text-blue-600 text-xl font-medium">Comment</p>
                <div class="border border-dashed border-gray-200 mt-2"></div>

                {getNftRating?.getNftRating?.map((comment, idx) => (
                  <>
                    <div className="flex items-center mb-4" key={idx}>
                      <img className="w-10 h-10 rounded-full me-4" src={comment?.user?.avatar_url} alt="" />
                      <div className="font-medium dark:text-white">
                        {/* <p>{comment?.user?.username}{" "} <time datetime="2014-08-16 19:00" className="block text-sm text-gray-800">Khushal@singh</time></p> */}
                        <p>
                          <span className="flex items-center gap-2">
                            {comment?.user?.username}{" "}
                            {comment?.user?.isVerified && (
                              <span className="flex items-center text-primaryPink cursor-pointer">
                                <Tooltip
                                  title="Verified"
                                  position="bottom"
                                  trigger="mouseenter"
                                >
                                  <MdVerified size={15} />
                                </Tooltip>
                              </span>
                            )}
                          </span>
                        </p>
                        <div className="flex items-center mb-1 space-x-1 rtl:space-x-reverse">
                          <Rate
                            className="text-[10px]"
                            value={comment.rating}
                            disabled="true"
                          />
                        </div>
                        <h3 className="text-sm font-normal text-gray-900 py-2">
                          {comment.comment}
                        </h3>
                      </div>
                    </div>
                  </>
                ))}
            </div>
        </div>
      </div>
    </>
  );
}

const InfoCards = ({ metadata, address, tokenId, ownerAddress, ipfsLink }) => {
  const nftDetails = [
    {
      title: "Contract Address",
      info: truncateAddress(address),
    },
    {
      title: "Token ID",
      info: tokenId,
    },
    {
      title: "Token Standard",
      info: "ERC-721",
    },

    {
      title: "Owner Address",
      info: truncateAddress(ownerAddress),
    },
  ];
  return (
    <>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-2">
        {/* //Description */}
        <div className="bg-white rounded-lg p-6">
            <p className="text-blue-600 text-xl font-medium">Description</p>
            <div class="border border-dashed border-gray-200 mt-2"></div>
            <p className="mt-2">By wg_deployer</p>
            <p className="mt-2">{metadata?.description}</p>
        </div>
        {/* Details Links */}
        <div className="bg-white rounded-lg p-6">
          <p className="text-blue-600 text-xl font-medium">Details</p>
          <div class="border border-dashed border-gray-200 mt-2"></div>
          <div class="flex mt-2">
            <a
              className=""
              href={getBlockExplore("contractAddress", address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mb-3 gap-3 items-center text-black hover:text-primaryPink ">
                <div>
                  <SiEthereum />
                </div>
                <div className="">View on Etherscan</div>
                <div>
                  <BsArrowUpRight className="text-primaryPink" />
                </div>
              </div>
            </a>
          </div>
          <div class="flex mt-2">
            <a
              href={replaceIPFS(ipfsLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex mb-3 gap-3 items-center text-black hover:text-primaryPink ">
                <div className="">
                  <SiIpfs />
                </div>
                <div className="">Open Original</div>
                <div>
                  <BsArrowUpRight className="text-primaryPink" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* //Properties */}

      <div className="bg-white rounded-lg p-6 my-5">
        <p className="text-blue-600 text-xl font-medium">Properties</p>
        <div class="border border-dashed border-gray-200 mt-2">
          <div className="p-5">
            <div>
              <div>
                <div className="grid sm:grid-cols-3  md:grid-cols-4 gap-5 ">
                  {metadata?.attributes?.map((property, index) => (
                    <div
                      className="border-primaryPink border font-bold inline-block rounded-md p-4 text-center"
                      key={index}
                    >
                      <div className="text-primaryPink">
                        {property.trait_type}
                      </div>
                      <div className="text-black">{property.subtitle}</div>
                      <div className=" text-[#8a939b] text-[13px]">
                        {property.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-white rounded-lg p-6 my-5">
        <p className="text-blue-600 text-xl font-medium">Bids Details</p>
        <div class="border border-dashed border-gray-200 mt-2"></div>
        <div className="py-2 flex justify-between">
            <p className="text-sm text-gray-600 text-overflow textwrap" >0x8bBE8D5027D274091666584A9179B535A3Fa69c3</p>
            <p className="text-[#FF3F9B] text-sm">0xe71…4757</p>
        </div>
      </div> */}

      {/* Details  */}
      <div className="bg-white rounded-lg p-6 my-5">
        <p className="text-blue-600 text-xl font-medium">NFT Details</p>
        <div class="border border-dashed border-gray-200 mt-2"></div>
        {nftDetails.map((value, index) => { 
            const chk_odd = isOdd(index);
          return (
            <div key={index} className={`py-2 flex justify-between mt-2 px-2 ${!chk_odd && 'bg-gray-100 rounded-sm'}`}>
              <p className="text-sm text-gray-600 text-overflow textwrap" >{value.title}</p>
              <p className="text-[#FF3F9B] text-sm">{value.info}</p>
            </div>
          )
        })}
      </div>
    </>
  );
};

export default ItemPage;
