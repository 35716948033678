import React, { createContext, useState } from "react";

export const TransactionContext = createContext();

function TransactionProvide({ children }) {
  const [TransactionDetails, setTransactionDetails] = useState([]);
  function addTransactionDetails(transaction) {
    setTransactionDetails([...TransactionDetails, transaction]);
  }
  return (
    <TransactionContext.Provider
      value={{
        TransactionDetails: TransactionDetails,
        addTransactionDetails: addTransactionDetails,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
}

export default TransactionProvide;
