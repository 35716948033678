import React from "react";
import exploreBanner from "../../assets/img/exploreBanner.jpg";
import { useQuery } from "@apollo/client";
import { GetStaticPage } from "../../graphql/query";

function Privacy() {

  const { data, loading, error } = useQuery(GetStaticPage, {
    variables: {
      type: 'privacy_policy',
    },
  });

  return (
    <>
      <div className='bg-blue-50'>
        <div className="absolute top-0 left-0 w-full h-1/4 bg-cover bg-bottom  lg:bg-[url('/assets/images/explorebg.svg')] bg-[url('/assets/images/smallmobilebgslider.webp')] bg-no-repeat "></div>
        <div className="relative z-[0]  px-4">
            <div className="container">
                <div className="row lg:flex block container pt-4">
                    <div className="w-full">
                        {/* <h1 className="text-white text-center text-4xl  font-bold">Privacy & Policy</h1> */}
                        <h1 className="text-white text-center text-4xl  font-bold">{data?.getStaticPage?.title}</h1>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">
        <div className="container pt-16">

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className="w-full text-center">
              <h1 className="text-2xl font-bold">Overview</h1>
              {/* <p className="py-4">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p> */}
              <p className="py-4">{data?.getStaticPage?.body?.overview}</p>
            </div>
          </div>

          <div className=" row lg:flex block lg:py-4 py-0 lg:p-4">
            <div className="w-full text-center">
              {/* <h1 className="text-2xl font-bold">Overview</h1> */}
              {/* <p className="py-4">In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc. There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.</p> */}
              <div className="py-4" dangerouslySetInnerHTML={{ __html: data?.getStaticPage?.body?.html }} />
            </div>
          </div>

          <div className="h-32"></div>

        </div>
      </div>
    </>
  );
}

export default Privacy;
