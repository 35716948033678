import React from "react";
import { BiPlus } from "react-icons/bi";
import { MdVerified } from "react-icons/md";

function CreatorCard({ banner, profile, username, follower, isVerify }) {
  return (
    <div>
      {" "}

        <div className='bg-white p-4 rounded-lg lg:mt-4 mt-0 shadow-2xl'>
          <div className='flex justify-between'>
            <div className="flex w-[65px] px-2  tems-center bg-[#9757D7] rounded-md">
              <span className="inline-block mr-3">
                  <img className="h-auto mt-1" src="/assets/images/Line.svg" alt="" width="auto" />
              </span>
              <h4 className="text-white">#{follower.length}</h4>
            </div>
            <div className="flex flex-end">
              {/* <img className="h-auto mt-1" src="/assets/images/plus.svg" alt="" width="auto" /> */}
              {isVerify && (
                <span className="h-auto mt-1">
                  <MdVerified size={15} />
                </span>
              )}
              {/* <img className="h-auto mt-1" src="/assets/images/arrow.svg" alt="" width="auto" /> */}
            </div>
          </div>
          <div className='py-4 text-center'>
            <img className="h-24 mx-auto" src={profile} alt="" width="auto" />
            <p>{username}</p>
            {/* <p>2.456 &nbsp;<span className='text-gray-500'>ETH</span></p> */}
          </div>
        </div>
        
    </div>
  );
}

export default CreatorCard;
